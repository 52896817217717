import React, { useState, useRef } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const TestCarouselPage = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1, // optional, default to 1.
    },
  };

  const responsiveTwo = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1, // optional, default to 1.
    },
  };

  const videoRefs = useRef([]);
  const videoRefsLarge = useRef([]);


  // Track the currently playing video index
  const [playingIndex, setPlayingIndex] = useState(null);

   // Function to pause all other videos when one is played
   const handleVideoPlay = (index) => {
    videoRefs.current.forEach((video, i) => {
      if (video && i !== index) {
        video.pause(); // Pause all other videos except the current one
      }
    });
    setPlayingIndex(index); // Set the currently playing video index
  };

  const handleVideoPause = () => {
    setPlayingIndex(null); // Reset the playing index when a video is paused
  };

  const handleVideoPlayLarge = (index) => {
    videoRefsLarge.current.forEach((video, i) => {
      if (video && i !== index) {
        video.pause(); // Pause all other videos except the current one
        video.currentTime = 0; // Optionally reset the time of the paused videos
      }
    });
  };
  const [showVideo, setShowVideo] = useState({}); // Track which video is being played
  // const videoRefs = useRef([]); // Store references to video elements

  // Function to toggle video playback for YouTube videos
  const toggleShowVideo = (url, index) => {
    setShowVideo((prevShowVideo) => ({
      ...prevShowVideo,
      [url]: !prevShowVideo[url],
    }));
    if (videoRefs.current[index]) {
      videoRefs.current[index].pause();
      videoRefs.current[index].currentTime = 0;
    }
  };

  // Function to handle pausing the video when moving to another slide
  const pauseVideoOnSlide = (currentIndex) => {
    setShowVideo({}); // Pauses all videos

    // Ensure that the current video (if playing) is paused and reset
    if (videoRefs.current[currentIndex]) {
      videoRefs.current[currentIndex].pause();
      videoRefs.current[currentIndex].currentTime = 0;
    }
  };

  // Function to handle video playback only for the active slide
  // const handleVideoPlay = (index) => {
  //   videoRefs.current.forEach((video, i) => {
  //     if (video) {
  //       if (i === index) {
  //         video.play(); // Play the current video
  //       } else {
  //         video.pause(); // Pause all other videos
  //         video.currentTime = 0; // Reset other videos
  //       }
  //     }
  //   });
  // };

  // Custom rendering for YouTube videos
  const renderVideo = (item, index) => {
    return (
      <div className="video-wrapper">
        {showVideo[item.embedUrl] ? (
          <div>
            <button
              className="close-video"
              onClick={() => toggleShowVideo(item.embedUrl, index)}
            >
              Close
            </button>
            <iframe
              width="100%"
              height="500"
              src={item.embedUrl}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title="video"
            ></iframe>
          </div>
        ) : (
          <div>
            <button onClick={() => toggleShowVideo(item.embedUrl, index)}>
              <div className="play-button"></div>
              <img src={item.original} alt="" className="image-gallery-image" />
            </button>
            {item.description && (
              <span className="image-gallery-description">
                {item.description}
              </span>
            )}
          </div>
        )}
      </div>
    );
  };

  // Video items
  const images = [
    {
      original: "https://i3.ytimg.com/vi/sGvh-WJjK60/maxresdefault.jpg",
      thumbnail: "https://i3.ytimg.com/vi/sGvh-WJjK60/hqdefault.jpg",
      embedUrl:
        "https://www.youtube.com/embed/sGvh-WJjK60?autoplay=1&showinfo=0",
      description: "YouTube Video 1",
      renderItem: renderVideo,
    },
    {
      original: "https://i3.ytimg.com/vi/tYGh2qnCl9I/maxresdefault.jpg",
      thumbnail: "https://i3.ytimg.com/vi/tYGh2qnCl9I/hqdefault.jpg",
      embedUrl:
        "https://www.youtube.com/embed/tYGh2qnCl9I?autoplay=1&showinfo=0",
      description: "YouTube Video 2",
      renderItem: renderVideo,
    },
    // Add more video or image items here
  ];

  // Reference to Carousel instance
  const carouselRef = useRef(null);
  const carouselRefTwo = useRef(null);

  // Function to manually trigger left navigation
  const handlePrevClickOne = () => {
    carouselRefTwo.current.previous();
  };

  // Function to manually trigger right navigation
  const handleNextClickOne = () => {
    carouselRefTwo.current.next();
  };

  // Function to manually trigger left navigation
  const handlePrevClick = () => {
    carouselRef.current.previous();
  };

  // Function to manually trigger right navigation
  const handleNextClick = () => {
    carouselRef.current.next();
  };

  const videos = [
    {
      src: "/images/testimonial3.mp4",
      // handlePlay: () => handleVideoPlay(0),
      refIndex: 0,
    },
    {
      src: "/images/testimonial5.mp4",
      // handlePlay: () => handleVideoPlay(1),
      refIndex: 1,
    },
    {
      src: "/images/testimonial2.mp4",
      // handlePlay: () => handleVideoPlay(2),
      refIndex: 2,
    },
    {
      src: "/images/testimonial4.mp4",
      // handlePlay: () => handleVideoPlay(2),
      refIndex: 3,
    },
    {
      src: "/images/testimonial1.mp4",
      // handlePlay: () => handleVideoPlay(2),
      refIndex: 4,
    },
    {
      src: "/images/testimonial6.mp4",
      // handlePlay: () => handleVideoPlay(2),
      refIndex: 5,
    },
  ];

  const videosLarge = [
    {
      src: "/images/testimonial1.mp4",
      handlePlay: () => handleVideoPlayLarge(0),
      refIndex: 0,
    },
    {
      src: "/images/testimonial5.mp4",
      handlePlay: () => handleVideoPlayLarge(1),
      refIndex: 1,
    },
    {
      src: "/images/testimonial2.mp4",
      handlePlay: () => handleVideoPlayLarge(2),
      refIndex: 2,
    },
    {
      src: "/images/testimonial3.mp4",
      handlePlay: () => handleVideoPlayLarge(3),
      refIndex: 3,
    },
    {
      src: "/images/testimonial6.mp4",
      handlePlay: () => handleVideoPlayLarge(4),
      refIndex: 4,
    },
    {
      src: "/images/testimonial4.mp4",
      handlePlay: () => handleVideoPlayLarge(5),
      refIndex: 5,
    },

    // {
    //   src: "/images/testimonialMatilda.mp4",
    //   handlePlay: () => handleVideoPlayLarge(0),
    //   refIndex: 0,
    // },
    // {
    //   src: "/images/testimonialFavour.mp4",
    //   handlePlay: () => handleVideoPlayLarge(1),
    //   refIndex: 1,
    // },
    // {
    //   src: "/images/testimonialLast.mp4",
    //   handlePlay: () => handleVideoPlayLarge(2),
    //   refIndex: 2,
    // },
  ];

  const [curr, setCurr] = useState(0);

  const prev = () =>
    setCurr((curr) => (curr === 0 ? videosLarge.length - 1 : curr - 1));

  const next = () =>
    setCurr((curr) => (curr === videosLarge.length - 1 ? 0 : curr + 1));

  return (
    <div>
      <div className="max-w-screen-xl px-8 lg:px-14 md:pt-10 pb-8 mx-auto lg:gap-20">
      <h2 className="font-semibold text-center pb-10 text-[#171717] text-[32px]">
                  Our Success Stories
                </h2>
        <div className="lg:grid hidden gap-3 md:gap-9 grid-cols-2">
      {/* <div className="lg:block hidden"> */}
    
        <div className="overflow-hidden flex justify-center text-center relative">
    <div
      className="flex transition-transform ease-out duration-500"
      style={{ transform: `translateX(-${curr * 100}%)`}} // Carousel container width
    >
      {videosLarge.map((video, index) => (
        <div key={index} className="w-full flex-shrink-0 h-[80vh] flex justify-center items-center"> 
         
          <div className={`relative w-full h-full `}>
            <video
              ref={(el) => (videoRefsLarge.current[video.refIndex] = el)} // Assign the reference dynamically
              className="w-full h-full object-contain" // Each video occupies the full width
              controls
              onPlay={video.handlePlay} // Call function when the video is played
            >
              <source src={video.src} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      ))}
    </div>

    <div className="absolute inset-y-0 left-0 flex items-center">
      <button
        className="p-1 rounded-full shadow bg-gray-800 text-white z-10"
        onClick={prev}
        style={{ pointerEvents: "auto" }} // Ensure buttons are clickable but don’t block the video
      >
        <FaChevronLeft size={40} />
      </button>
    </div>

    <div className="absolute inset-y-0 right-0 flex items-center">
      <button
        className="p-1 rounded-full shadow bg-gray-800 text-white z-10"
        onClick={next}
        style={{ pointerEvents: "auto" }} // Ensure buttons are clickable but don’t block the video
      >
        <FaChevronRight size={40} />
      </button>
    </div>
  </div>

          {/* <ImageGallery
        items={images}
        renderItem={(item, index) => item.renderItem(item, index)} // Render video or image
        renderThumb={(item) =>
          item.embedUrl ? (
            <img src={item.thumbnail} alt="thumbnail" />
          ) : (
            <img src={item.thumbnail} alt="" />
          )
        } // Render thumbnail for videos
        // onSlide={pauseVideoOnSlide} // Pause video when changing slides
        showPlayButton={false} // Hide default play button if needed
      /> */}

          {/* <Carousel
        ref={carouselRefThree}
        swipeable={true}
        draggable={true}
        showDots={false}
        responsive={responsive}
        ssr={true} // render carousel on server-side.
        infinite={true}
        rtl={false}
        transitionDuration={500} // Set transition to 500ms for smoother experience
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        className="rounded-[7px]"
        // afterChange={handleSlideChange} // Call the function when slide changes
    >
 <video
        ref={(el) => (videoRefs.current[0] = el)} // Assign the reference for the first video
        className="w-full hidden md:block h-[80vh]"
        controls
        onPlay={() => handleVideoPlay(0)} // Call function when the first video is played
      >
        <source src="/images/testimonialMatilda.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <video
        ref={(el) => (videoRefs.current[1] = el)} // Assign the reference for the second video
        className="w-full hidden md:block h-[80vh]"
        controls
        onPlay={() => handleVideoPlay(1)} // Call function when the second video is played
      >
        <source src="/images/testimonialFavour.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <video
        ref={(el) => (videoRefs.current[2] = el)} // Assign the reference for the third video
        className="w-full hidden md:block h-[80vh]"
        controls
        onPlay={() => handleVideoPlay(2)} // Call function when the third video is played
      >
        <source src="/images/testimonialLast.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

            </Carousel> */}

          <div className="relative">
            {/* Custom Arrow Buttons Positioned in Front of the Heading */}
            <div className="flex justify-between items-center mb-5">
              <div className="">
               
                <h4 className="text-[25px] font">What people say about us</h4>
              </div>

              <div className="flex gap-2">
                <button
                  className="bg-[#48B774] text-white p-2 rounded-full"
                  onClick={handlePrevClickOne}
                >
                  <IoIosArrowBack />
                </button>

                <button
                  className="bg-[#48B774] text-white p-2 rounded-full"
                  onClick={handleNextClickOne}
                >
                  <IoIosArrowForward />
                </button>
              </div>
            </div>

            {/* Custom Arrow Buttons Positioned in Front of the Heading */}
            {/* <CustomLeftArrow onClick={() => {}} />
      <CustomRightArrow onClick={() => {}} /> */}
            <div className="">
              <Carousel
                ref={carouselRefTwo}
                swipeable={true}
                draggable={true}
                showDots={true}
                responsive={responsive}
                ssr={true} // render carousel on server-side.
                infinite={true}
                rtl={false}
                autoPlay={true} // Disable autoplay to prevent conflict with manual navigation
                autoPlaySpeed={7000} // Optional: You can remove this if autoplay is disabled
                // keyBoardControl={true}
                transitionDuration={500} // Set transition to 500ms for smoother experience
                containerClass="carousel-container"
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                className="rounded-[7px]"
                arrows={false} // Hide default arrows
              >
                <div className="flex flex-col gap-4">
                  <div className="bg-white mt-5 px-3 pb-6 h-[30vh] rounded-lg border shadow-lg">
                    <div className="flex flex-col lg:flex-row lg:space-x-6">
                      <img
                        src="/images/peter.png"
                        alt="location"
                        className="md:w-[20%] md:h-[20%] mt-3"
                      />
                      <div className="my-3 ">
                        <img src="/images/star.svg" alt="location" />
                        <p className="max-w-3xl mt-2 font-normal text-gray-500 mb-2 text-[12px]">
                          I recently had a remarkable language experience that I
                          would like to share. It was a transformative
                          experience that I highly recommend to anyone seeking
                          personal and linguistic growth. A very big thank you
                          to AfriProEdu for helping me with my academic journey
                          to Finland.
                        </p>
                        <h1 className="text-base font-semibold">Peter</h1>
                      </div>
                    </div>
                  </div>

                  <div className="bg-white mt-5 px-3 pb-6 h-[30vh] rounded-lg border shadow-lg">
                    <div className="flex flex-col lg:flex-row lg:space-x-6">
                      <img
                        src="/images/matilda.svg"
                        alt="location"
                        className="md:w-[20%] md:h-[20%] mt-3"
                      />
                      <div className="my-3 ">
                        <img src="/images/star.svg" alt="location" />
                        <p className="max-w-3xl mt-2 font-normal text-gray-500 mb-2 text-[12px]">
                          I’m very happy I decided to apply to learn Finnish
                          language through AfriProedu. I heard about the program
                          from AfriProedu and they have being of huge help to
                          this achievement.They are very legit and safe and I
                          highly recommend them for everyone. Kiitos tosi paljon
                          AfriProedu.
                        </p>
                        <h1 className="text-base font-semibold">Matilda</h1>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-4">
                  <div className="bg-white mt-5 px-3 pb-6 h-[30vh] rounded-lg border shadow-lg">
                    <div className="flex flex-col lg:flex-row lg:space-x-6">
                      <img
                        src="/images/favour.png"
                        alt="location"
                        className="md:w-[20%] md:h-[20%] mt-3"
                      />
                      <div className="my-3 ">
                        <img src="/images/star.svg" alt="location" />
                        <p className="max-w-3xl mt-2 font-normal text-gray-500 mb-2 text-[12px]">
                          Applying to learn the Finnish language program through
                          AfriproEdu was a wonderful decision I made. I heard
                          about the program through AfriProEdu, and they have
                          been of great help to this achievement. They are very
                          legit and safe. And I recommend AfriproEdu for
                          everyone. Kiitos AfriproEdu!
                        </p>
                        <h1 className="text-base  font-semibold">Favour</h1>
                      </div>
                    </div>
                  </div>

                  <div className="bg-white mt-5 px-3 pb-6 h-[30vh] rounded-lg border shadow-lg">
                    <div className="flex flex-col lg:flex-row lg:space-x-6">
                      <img
                        src="/images/Osose.png"
                        alt="location"
                        className="md:w-[20%] md:h-[20%] mt-3"
                      />
                      <div className="my-3 ">
                        <img src="/images/star.svg" alt="location" />
                        <p className="max-w-3xl mt-2 font-normal text-gray-500 mb-2 text-[12px]">
                          Learning The Finnish language through AfriProEdu with
                          Finest Future has been a lot more fun and helpful than
                          I could have ever imagined. Not only have I learnt the
                          language and Finnish culture but I’ve learnt about
                          teamwork and social skills in the classes.
                        </p>
                        <h1 className="text-base  font-semibold">Osose</h1>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-4">
                  <div className="bg-white mt-5 px-3 pb-6 h-[30vh] rounded-lg border shadow-lg">
                    <div className="flex flex-col lg:flex-row lg:space-x-6">
                      <img
                        src="/images/Ornela.png"
                        alt="location"
                        className="md:w-[20%] md:h-[20%] mt-3"
                      />
                      <div className="my-3 ">
                        <img src="/images/star.svg" alt="location" />
                        <p className="max-w-3xl mt-2 font-normal text-gray-500 mb-2 text-[12px]">
                          Studying Finnish under AfriProEdu and Finest Future
                          has been an awesome and fascinating program for all
                          foreigners who want to five, stay and work in Finland.
                          Studying Finnish for all this time has been
                          captivating.{" "}
                        </p>
                        <h1 className="text-base  font-semibold">Ornela</h1>
                      </div>
                    </div>
                  </div>

                  <div className="bg-white mt-5 px-3 pb-6 h-[30vh] rounded-lg border shadow-lg">
                    <div className="flex flex-col lg:flex-row lg:space-x-6">
                      <img
                        src="/images/Phoebe.png"
                        alt="location"
                        className="md:w-[20%] md:h-[20%] mt-3"
                      />
                      <div className="my-3 ">
                        <img src="/images/star.svg" alt="location" />
                        <p className="max-w-3xl mt-2 font-normal text-gray-500 mb-2 text-[12px]">
                          AfriProEdu and Finnest future program has highly
                          competent teachers who made learning easy and
                          interesting, as well as provide opportunities for
                          foreign students to achieve their dreams.{" "}
                        </p>
                        <h1 className="text-base  font-semibold">Phoebe</h1>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-4">
                  <div className="bg-white mt-5 px-3 pb-6 h-[30vh] rounded-lg border shadow-lg">
                    <div className="flex flex-col lg:flex-row lg:space-x-6">
                      <img
                        src="/images/Elodie.png"
                        alt="location"
                        className="md:w-[20%] md:h-[20%] mt-3"
                      />
                      <div className="my-3 ">
                        <img src="/images/star.svg" alt="location" />
                        <p className="max-w-3xl mt-2 font-normal text-gray-500 mb-2 text-[12px]">
                          Learning Finnish has been a great experience so far,
                          thanks to AfriProEdu and Finnest future. Before being
                          part of this program, I wouldn't have never imagined
                          learning a new language. So, I would say that
                          AfriProEdu and Finnest future made me believe in
                          myself.{" "}
                        </p>
                        <h1 className="text-base  font-semibold">Elodie</h1>
                      </div>
                    </div>
                  </div>

                  <div className="bg-white mt-5 px-3 pb-6 h-[30vh] rounded-lg border shadow-lg">
                    <div className="flex flex-col lg:flex-row lg:space-x-6">
                      <img
                        src="/images/Phoebe.png"
                        alt="location"
                        className="md:w-[20%] md:h-[20%] mt-3"
                      />
                      <div className="my-3 ">
                        <img src="/images/star.svg" alt="location" />
                        <p className="max-w-3xl mt-2 font-normal text-gray-500 mb-2 text-[12px]">
                          AfriProEdu and Finnest future program has highly
                          competent teachers who made learning easy and
                          interesting, as well as provide opportunities for
                          foreign students to achieve their dreams.{" "}
                        </p>
                        <h1 className="text-base  font-semibold">Phoebe</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>

          {/* <div className="flex justify-center ">
      <iframe className="w-full hidden md:block h-[50vh] aspect-video " src="https://www.youtube.com/embed/8x1IGsdKqyE?si=YZil-va8bat0FxHn" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>
      <iframe className="w-[70vw] md:hidden block h-full aspect-video " src="https://www.youtube.com/embed/8x1IGsdKqyE?si=YZil-va8bat0FxHn" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>
     </div> */}
          {/*         
          <Carousel cols={2} rows={1} gap={30} loop>
            <Carousel.Item>
              <div className="bg-white  mt-5 px-6 pb-6 rounded-lg border shadow-lg">
            
                <div className="flex flex-col lg:flex-row lg:space-x-6">
                  <img
                    src="/images/peter.png"
                    alt="location"
                    className="md:w-[30%] md:h-[30%] mt-3"
                  />
                  <div className="mt-3 ">
                    <img src="/images/star.svg" alt="location" />
                    <p className="max-w-2xl mb-6 md:mt-6 mt-2 font-normal text-gray-500  lg:mb-8 md:text-sm text-[10px]">
                      I recently had a remarkable language experience that I
                      would like to share. It was a transformative experience
                      that I highly recommend to anyone seeking personal and
                      linguistic growth. A very big thank you to AfriProEdu for
                      helping me with my academic journey to Finland.
                    </p>
                    <h1 className="text-base  font-semibold">Peter</h1>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="bg-white  mt-5 px-6 pb-6 rounded-lg border shadow-lg">
              
                <div className="flex flex-col lg:flex-row lg:space-x-6">
                  <img
                    src="/images/matilda.svg"
                    alt="location"
                    className="md:w-[30%] md:h-[30%] mt-3"
                  />
                  <div className="mt-3 ">
                    <img src="/images/star.svg" alt="location" />
                    <p className="max-w-2xl mb-6 md:mt-6 mt-2 font-normal text-gray-500  lg:mb-8 md:text-sm text-[10px]">
                      I’m very happy I decided to apply to learn Finnish
                      language through AfriProedu. I heard about the program
                      from AfriProedu and they have being of huge help to this
                      achievement.They are very legit and safe and I highly
                      recommend them for everyone. Kiitos tosi paljon AfriProedu
                      .
                    </p>
                    <h1 className="text-base  font-semibold">Matilda</h1>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="bg-white  mt-5 px-6 pb-6 rounded-lg border shadow-lg">
         
                <div className="flex flex-col lg:flex-row lg:space-x-6">
                  <img
                    src="/images/favour.png"
                    alt="location"
                    className="md:w-[30%] md:h-[30%] mt-3"
                  />
                  <div className="mt-3 ">
                    <img src="/images/star.svg" alt="location" />
                    <p className="max-w-2xl mb-6 md:mt-6 mt-2 font-normal text-gray-500  lg:mb-8 md:text-sm text-[10px]">
                      Applying to learn the Finnish language program through
                      AfriproEdu was a wonderful decision I made. I heard about
                      the program through AfriProEdu, and they have been of
                      great help to this achievement. They are very legit and
                      safe. And I recommend AfriproEdu for everyone. Kiitos
                      AfriproEdu!
                    </p>
                    <h1 className="text-base  font-semibold">Favour</h1>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="bg-white  mt-5 px-6 pb-6 rounded-lg border shadow-lg">
             
                <div className="flex flex-col lg:flex-row lg:space-x-6">
                <img
                    src="/images/Osose.png"
                    alt="location"
                    className="md:w-[30%] md:h-[30%] mt-3"
                  />
                  <div className="mt-3 ">
                    <img src="/images/star.svg" alt="location" />
                    <p className="max-w-2xl mb-6 md:mt-6 mt-2 font-normal text-gray-500  lg:mb-8 md:text-sm text-[10px]">
                      Learning The Finnish language through AfriProEdu with
                      Finest Future has been a lot more fun and helpful than I
                      could have ever imagined. Not only have I learnt the
                      language and Finnish culture but I’ve learnt about
                      teamwork and social skills in the classes.
                    </p>
                    <h1 className="text-base  font-semibold">Osose</h1>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="bg-white  mt-5 px-6 pb-6 rounded-lg border shadow-lg">
            
                <div className="flex flex-col lg:flex-row lg:space-x-6">
                <img
                    src="/images/Ornela.png"
                    alt="location"
                    className="md:w-[30%] md:h-[30%] mt-3"
                  />
                  <div className="mt-3 ">
                    <img src="/images/star.svg" alt="location" />
                    <p className="max-w-2xl mb-6 md:mt-6 mt-2 font-normal text-gray-500  lg:mb-8 md:text-sm text-[10px]">
                      Studying Finnish under AfriProEdu and Finest Future has
                      been an awesome and fascinating program for all foreigners
                      who want to five, stay and work in Finland. Studying
                      Finnish for all this time has been captivating.{" "}
                    </p>
                    <h1 className="text-base  font-semibold">Ornela</h1>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="bg-white  mt-5 px-6 pb-6 rounded-lg border shadow-lg">
           
                <div className="flex flex-col lg:flex-row lg:space-x-6">
             <img
                    src="/images/Phoebe.png"
                    alt="location"
                    className="md:w-[30%] md:h-[30%] mt-3"
                  />
                  <div className=" mt-5">
                    <img src="/images/star.svg" alt="location" />
                    <p className="max-w-2xl mb-6 mt-6 font-normal text-gray-500  lg:mb-8 md:text-sm text-[10px]">
                      AfriProEdu and Finnest future program has highly competent
                      teachers who made learning easy and interesting, as well
                      as provide opportunities for foreign students to achieve
                      their dreams.{" "}
                    </p>
                    <h1 className="text-base  font-semibold">Phoebe</h1>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="bg-white  mt-5 px-6 pb-6 rounded-lg border shadow-lg">
           
                <div className="flex flex-col lg:flex-row lg:space-x-6">
             <img
                    src="/images/Elodie.png"
                    alt="location"
                    className="md:w-[30%] md:h-[30%] mt-3"
                  />
                  <div className=" mt-5">
                    <img src="/images/star.svg" alt="location" />
                    <p className="max-w-2xl mb-6 mt-6 font-normal text-gray-500  lg:mb-8 md:text-sm text-[10px]">
                      Learning Finnish has been a great experience so far,
                      thanks to AfriProEdu and Finnest future. Before being part
                      of this program, I wouldn't have never imagined learning a
                      new language. So, I would say that AfriProEdu and Finnest
                      future made me believe in myself.{" "}
                    </p>
                    <h1 className="text-base  font-semibold">Elodie</h1>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel> */}
        </div>

        <div className=" lg:hidden ">
          <div className="relative mb-8">
            {/* Custom Arrow Buttons Positioned in Front of the Heading */}
            <div className="flex justify-between items-center mb-5">
              <div className="">
                {/* <h2 className="font-semibold text-[#171717] text-[25px]">
                  Our Success Stories
                </h2> */}
                <h4 className="text-[18px]">What people say about us</h4>
              </div>

              <div className="flex gap-2">
                <button
                  className="bg-[#48B774] text-white p-2 rounded-full"
                  onClick={handlePrevClick}
                >
                  <IoIosArrowBack />
                </button>

                <button
                  className="bg-[#48B774] text-white p-2 rounded-full"
                  onClick={handleNextClick}
                >
                  <IoIosArrowForward />
                </button>
              </div>
            </div>

            {/* Custom Arrow Buttons Positioned in Front of the Heading */}
            {/* <CustomLeftArrow onClick={() => {}} />
      <CustomRightArrow onClick={() => {}} /> */}
            <div className="">
              <Carousel
                ref={carouselRef}
                swipeable={true}
                draggable={true}
                showDots={true}
                responsive={responsive}
                ssr={true} // render carousel on server-side.
                infinite={false}
                rtl={false}
                // autoPlay={false} // Disable autoplay to prevent conflict with manual navigation
                // autoPlaySpeed={3000} // Optional: You can remove this if autoplay is disabled
                // keyBoardControl={true}
                transitionDuration={500} // Set transition to 500ms for smoother experience
                containerClass="carousel-container"
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                className="rounded-[7px]"
                arrows={false} // Hide default arrows
              >
                <div className="bg-white mt-5 px-3 pb-6  rounded-lg border shadow-lg">
                  <div className="flex flex-row space-x-2 lg:space-x-6">
                    <img
                      src="/images/peter.png"
                      alt="location"
                      className="w-[35%] h-[35%] mt-3"
                    />
                    <div className="my-3 ">
                      <img src="/images/star.svg" alt="location" />
                      <p className="max-w-3xl mt-2 font-normal text-gray-500 mb-2 text-[12px]">
                        I recently had a remarkable language experience that I
                        would like to share. It was a transformative experience
                        that I highly recommend to anyone seeking personal and
                        linguistic growth. A very big thank you to AfriProEdu
                        for helping me with my academic journey to Finland.
                      </p>
                      <h1 className="text-base font-semibold">Peter</h1>
                    </div>
                  </div>
                </div>

                <div className="bg-white mt-5 px-3 pb-6 rounded-lg border shadow-lg">
                  <div className="flex space-x-2 lg:space-x-6">
                    <img
                      src="/images/matilda.svg"
                      alt="location"
                      className="w-[35%] h-[35%] mt-3"
                    />
                    <div className="my-3 ">
                      <img src="/images/star.svg" alt="location" />
                      <p className="max-w-3xl mt-2 font-normal text-gray-500 mb-2 text-[12px]">
                        I’m very happy I decided to apply to learn Finnish
                        language through AfriProedu. I heard about the program
                        from AfriProedu and they have being of huge help to this
                        achievement.They are very legit and safe and I highly
                        recommend them for everyone. Kiitos tosi paljon
                        AfriProedu.
                      </p>
                      <h1 className="text-base font-semibold">Matilda</h1>
                    </div>
                  </div>
                </div>

                <div className="bg-white mt-5 px-3 pb-6 rounded-lg border shadow-lg">
                  <div className="flex space-x-2 g:space-x-6">
                    <img
                      src="/images/favour.png"
                      alt="location"
                      className="w-[35%] h-[35%] mt-3"
                    />
                    <div className="my-3 ">
                      <img src="/images/star.svg" alt="location" />
                      <p className="max-w-3xl mt-2 font-normal text-gray-500 mb-2 text-[12px]">
                        Applying to learn the Finnish language program through
                        AfriproEdu was a wonderful decision I made. I heard
                        about the program through AfriProEdu, and they have been
                        of great help to this achievement. They are very legit
                        and safe. And I recommend AfriproEdu for everyone.
                        Kiitos AfriproEdu!
                      </p>
                      <h1 className="text-base  font-semibold">Favour</h1>
                    </div>
                  </div>
                </div>

                <div className="bg-white mt-5 px-3 pb-6  rounded-lg border shadow-lg">
                  <div className="flex space-x-2 lg:space-x-6">
                    <img
                      src="/images/Osose.png"
                      alt="location"
                      className="w-[35%] h-[35%] mt-3"
                    />
                    <div className="my-3 ">
                      <img src="/images/star.svg" alt="location" />
                      <p className="max-w-3xl mt-2 font-normal text-gray-500 mb-2 text-[12px]">
                        Learning The Finnish language through AfriProEdu with
                        Finest Future has been a lot more fun and helpful than I
                        could have ever imagined. Not only have I learnt the
                        language and Finnish culture but I’ve learnt about
                        teamwork and social skills in the classes.
                      </p>
                      <h1 className="text-base  font-semibold">Osose</h1>
                    </div>
                  </div>
                </div>

                <div className="bg-white mt-5 px-3 pb-6  rounded-lg border shadow-lg">
                  <div className="flex space-x-2 lg:space-x-6">
                    <img
                      src="/images/Ornela.png"
                      alt="location"
                      className="w-[35%] h-[35%] mt-3"
                    />
                    <div className="my-3 ">
                      <img src="/images/star.svg" alt="location" />
                      <p className="max-w-3xl mt-2 font-normal text-gray-500 mb-2 text-[12px]">
                        Studying Finnish under AfriProEdu and Finest Future has
                        been an awesome and fascinating program for all
                        foreigners who want to five, stay and work in Finland.
                        Studying Finnish for all this time has been captivating.{" "}
                      </p>
                      <h1 className="text-base  font-semibold">Ornela</h1>
                    </div>
                  </div>
                </div>

                <div className="bg-white mt-5 px-3 pb-6  rounded-lg border shadow-lg">
                  <div className="flex space-x-2 lg:space-x-6">
                    <img
                      src="/images/Phoebe.png"
                      alt="location"
                      className="w-[35%] h-[35%] mt-3"
                    />
                    <div className="my-3 ">
                      <img src="/images/star.svg" alt="location" />
                      <p className="max-w-3xl mt-2 font-normal text-gray-500 mb-2 text-[12px]">
                        AfriProEdu and Finnest future program has highly
                        competent teachers who made learning easy and
                        interesting, as well as provide opportunities for
                        foreign students to achieve their dreams.{" "}
                      </p>
                      <h1 className="text-base  font-semibold">Phoebe</h1>
                    </div>
                  </div>
                </div>

                <div className="bg-white mt-5 px-3 pb-6 rounded-lg border shadow-lg">
                  <div className="flex space-x-2 lg:space-x-6">
                    <img
                      src="/images/Elodie.png"
                      alt="location"
                      className="w-[35%] h-[35%] mt-3"
                    />
                    <div className="my-3 ">
                      <img src="/images/star.svg" alt="location" />
                      <p className="max-w-3xl mt-2 font-normal text-gray-500 mb-2 text-[12px]">
                        Learning Finnish has been a great experience so far,
                        thanks to AfriProEdu and Finnest future. Before being
                        part of this program, I wouldn't have never imagined
                        learning a new language. So, I would say that AfriProEdu
                        and Finnest future made me believe in myself.{" "}
                      </p>
                      <h1 className="text-base  font-semibold">Elodie</h1>
                    </div>
                  </div>
                </div>

                <div className="bg-white mt-5 px-3 pb-6 rounded-lg border shadow-lg">
                  <div className="flex space-x-2 lg:space-x-6">
                    <img
                      src="/images/Phoebe.png"
                      alt="location"
                      className="w-[35%] h-[35%] mt-3"
                    />
                    <div className="my-3 ">
                      <img src="/images/star.svg" alt="location" />
                      <p className="max-w-3xl mt-2 font-normal text-gray-500 mb-2 text-[12px]">
                        AfriProEdu and Finnest future program has highly
                        competent teachers who made learning easy and
                        interesting, as well as provide opportunities for
                        foreign students to achieve their dreams.{" "}
                      </p>
                      <h1 className="text-base  font-semibold">Phoebe</h1>
                    </div>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>

          <div className="overflow-hidden flex justify-center text-center relative">
    <div
      className="flex transition-transform ease-out duration-500"
      style={{ transform: `translateX(-${curr * 100}%)`, width: `${videos.length * 100}%` }} // Carousel container width
    >
      {videos.map((video, index) => (
        <div key={index} className="w-full flex-shrink-0 h-[50vh] flex justify-center items-center"> 
      
          <div className={`relative w-full h-full ${index === 2 ? "bg-white" : ""}`}>
            <video
              ref={(el) => (videoRefs.current[index] = el)} // Assign the reference dynamically
              className="w-full h-full object-contain" // Each video occupies the full width
              controls
              onPlay={() => handleVideoPlay(index)} // Call function when the video is played
              onPause={handleVideoPause} // Handle when the video is paused
              >
              <source src={video.src} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      ))}
    </div>

    <div className="absolute inset-y-0 left-0 flex items-center">
      <button
        className="p-1 rounded-full shadow bg-gray-800 text-white z-10"
        onClick={prev}
        style={{ pointerEvents: "auto" }} // Ensure buttons are clickable but don’t block the video
      >
        <FaChevronLeft size={40} />
      </button>
    </div>

    <div className="absolute inset-y-0 right-0 flex items-center">
      <button
        className="p-1 rounded-full shadow bg-gray-800 text-white z-10"
        onClick={next}
        style={{ pointerEvents: "auto" }} // Ensure buttons are clickable but don’t block the video
      >
        <FaChevronRight size={40} />
      </button>
    </div>
  </div>
        </div>
      </div>
    </div>
  );
};

export default TestCarouselPage;
