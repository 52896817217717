import React, { useState } from "react";
import { Link, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import Navbar from "../../Navbar";
import { ToastContainer, toast } from "react-toastify";
import { MutatingDots } from "react-loader-spinner";
import "react-responsive-modal/styles.css";
import { AuthApis } from "../../../apis/authApis";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import { TbCopy } from "react-icons/tb";
import { MdOutlineMail } from "react-icons/md";
import { TbBrandWhatsapp } from "react-icons/tb";

const StudyProgramOverview = () => {
  const params: any = useParams();
  let course: string = params?.courseId;
  const location = useLocation(); // Access the location object
  const { datas } = location.state || {};
  const [loader, setLoader] = React.useState<boolean>(false);
  const [open, setOpen] = useState(true);
  const onCloseModal = () => setOpen(false);

  const navigate = useNavigate();
  const [userData, setUserData] = React.useState({
    firstname: "",
    lastname: "",
    email: "",
    country: "",
    phone: "",
    gender: "",
    academic_background: {
      highSchool: false,
      HND: false,
      bachelors: false,
      masters: false,
    } as Record<string, boolean>,
    current_document: {
      internationalPassport: false,
      waecCertificate: false,
      bscCertificate: false,
      aLevelCertificate: false,
      bscTranscript: false,
      CV: false,
      recommendationLetters: false,
      none: false,
    } as Record<string, boolean>,
  });


  const handleChange = (
    e: any
  ) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      const [category, field] = name.split(".");
      setUserData((prev:any) => ({
        ...prev,
        [category]: {
          ...prev[category],
          [field]: checked,
        },
      }));
    } else {
      setUserData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };




const [openPayment, setOpenPayment] = useState(false);
    const onOpenPaymentModal = () => {
       
        setOpenPayment(true);
    };
    const onClosePaymentModal = () => {
        setOpenPayment(false);
        onOpenPaymentProofModal();
    };
    const [openPaymentProof, setOpenPaymentProof] = useState(false);
    const onOpenPaymentProofModal = () => {
       
        setOpenPaymentProof(true);
    };
    const onClosePaymentProofModal = () => {
        setOpenPaymentProof(false);
        
        window.location.reload()
    };

const [copiedField, setCopiedField] = useState<string | null>(null);
    const copyToClipboard = (text: string, field: string) => {
        navigator.clipboard.writeText(text);
        setCopiedField(field);
        setTimeout(() => setCopiedField(null), 2000);
    };

  const handleSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setLoader(true);

      const payload = {
        firstname: userData.firstname,
        lastname: userData.lastname,
        email: userData.email,
        country: userData.country,
        phone: userData.phone,
        gender: userData.gender,
        academic_background: userData.academic_background,
        current_document: userData.current_document,
      };
// console.log(payload)
      AuthApis.lithuaniaApplication(payload)
        .then((response:any) => {
          if (response?.data) {
            toast.success(response?.data?.message);
            // console.log(response?.data);
      setLoader(false);
      // window.location.href = response.data.payment_link;  // Redirect to the payment link
      onOpenPaymentModal()
          }
        })
        .catch((err) => {
          if (err?.response?.status === 422) {
            toast.error("Form not correctly filled. Please check your inputs.");
      setLoader(false);
          
          } else {
            toast.error("An error occurred. Please try again.");
      setLoader(false);
         
          }
        })
        .finally(() => {
          setLoader(false);
        });
    },
    [userData]
  );
  const navigatetoLithuania = () => {
    navigate("/study-in-lithuania/study-program")
  }
  return (
    <div>
      <Navbar />

      <div className="pt-[80px]">
        <div className="bg-[#098A3C] relative py-6 sm:px-16 md:px-14 px-6">
          <div
            className=" absolute top-2 text-white text-[32px] rounded-lg left-6 shadow-lg md:h-40 cursor-pointer"
            onClick={() => navigate(-1)}
          >
            {" "}
            ←{" "}
          </div>
          <div className="flex justify-center my-10">
            <h5 className="text-white md:text-start text-center text-[30px] md:text-[60px] ">
              Study Program
            </h5>
          </div>
        </div>

        <div className="md:pt-20 pt-10 flex justify-center">
          <div className="max-w-screen-[1500px] w-full mx-auto px-8 lg: ">
            <div className="bg-white py-8 px-3">
              <div className="md:flex gap-7 items-center">
                <div className="md:flex flex-col gap-2">
                  <h4 className="text-[#1DB459] text-[25px] md:max-w-[450px] md:text-[40px] font-bold ">
                    {datas.name}
                  </h4>
                  <h4 className="text-[20px] ">
                    Program: <span className="font-bold">{datas?.type}</span>
                  </h4>
                </div>

                <div>
                  <h4 className="text-[#1DB459] text-[20px]  ">Requirement</h4>
                  <h4 className=" text-[20px] ">
                    {datas.requirement.map((dat: any) => (
                      <div className="flex flex-col">{dat}</div>
                    ))}
                  </h4>
                </div>
              </div>
            </div>

            <div className="">
            <form onSubmit={handleSubmit}>
      <h3 className="mt-5 text-[#1DB459] mb-6 font-medium text-[20px]">
        PERSONAL INFORMATION
      </h3>
      <div className="grid md:grid-cols-2 gap-5">
        {/* Input fields */}
        <div className="w-full">
          <label className="text-[12px] text-[#0A2E04]">First Name</label>
          <input
            type="text"
            className="border border-[#1DB459] text-[#333333] text-sm block w-full p-3"
            placeholder="Enter Name"
            name="firstname"
            value={userData.firstname}
            onChange={handleChange}
            required
          />
        </div>
        <div className="w-full">
          <label className="text-[12px] text-[#0A2E04]">Last Name</label>
          <input
            type="text"
            className="border border-[#1DB459] text-[#333333] text-sm block w-full p-3"
            placeholder="Enter Name"
            name="lastname"
            value={userData.lastname}
            onChange={handleChange}
            required
          />
        </div>
        <div className="w-full">
          <label className="text-[12px] text-[#0A2E04]">Location (country)</label>
          <select
                                        name="country"
                                        onChange={handleChange}
            value={userData.country}
                                        required
                                        className="border border-[#1DB459] text-[#333333] text-sm block w-full p-3"

                                    >
                                        <option></option>
                                        <option value="Afghanistan">Afghanistan</option>
                                        <option value="Albania">Albania</option>
                                        <option value="Algeria">Algeria</option>
                                        <option value="American Samoa">American Samoa</option>
                                        <option value="Andorra">Andorra</option>
                                        <option value="Angola">Angola</option>
                                        <option value="Anguilla">Anguilla</option>
                                        <option value="Antartica">Antarctica</option>
                                        <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                        <option value="Argentina">Argentina</option>
                                        <option value="Armenia">Armenia</option>
                                        <option value="Aruba">Aruba</option>
                                        <option value="Australia">Australia</option>
                                        <option value="Austria">Austria</option>
                                        <option value="Azerbaijan">Azerbaijan</option>
                                        <option value="Bahamas">Bahamas</option>
                                        <option value="Bahrain">Bahrain</option>
                                        <option value="Bangladesh">Bangladesh</option>
                                        <option value="Barbados">Barbados</option>
                                        <option value="Belarus">Belarus</option>
                                        <option value="Belgium">Belgium</option>
                                        <option value="Belize">Belize</option>
                                        <option value="Benin">Benin</option>
                                        <option value="Bermuda">Bermuda</option>
                                        <option value="Bhutan">Bhutan</option>
                                        <option value="Bolivia">Bolivia</option>
                                        <option value="Bosnia and Herzegowina">Bosnia and Herzegowina</option>
                                        <option value="Botswana">Botswana</option>
                                        <option value="Bouvet Island">Bouvet Island</option>
                                        <option value="Brazil">Brazil</option>
                                        <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                        <option value="Brunei Darussalam">Brunei Darussalam</option>
                                        <option value="Bulgaria">Bulgaria</option>
                                        <option value="Burkina Faso">Burkina Faso</option>
                                        <option value="Burundi">Burundi</option>
                                        <option value="Cambodia">Cambodia</option>
                                        <option value="Cameroon">Cameroon</option>
                                        <option value="Canada">Canada</option>
                                        <option value="Cape Verde">Cape Verde</option>
                                        <option value="Cayman Islands">Cayman Islands</option>
                                        <option value="Central African Republic">Central African Republic</option>
                                        <option value="Chad">Chad</option>
                                        <option value="Chile">Chile</option>
                                        <option value="China">China</option>
                                        <option value="Christmas Island">Christmas Island</option>
                                        <option value="Cocos Islands">Cocos (Keeling) Islands</option>
                                        <option value="Colombia">Colombia</option>
                                        <option value="Comoros">Comoros</option>
                                        <option value="Congo">Congo</option>
                                        <option value="Congo">Congo, the Democratic Republic of the</option>
                                        <option value="Cook Islands">Cook Islands</option>
                                        <option value="Costa Rica">Costa Rica</option>
                                        <option value="Cota D'Ivoire">Cote d'Ivoire</option>
                                        <option value="Croatia">Croatia (Hrvatska)</option>
                                        <option value="Cuba">Cuba</option>
                                        <option value="Cyprus">Cyprus</option>
                                        <option value="Czech Republic">Czech Republic</option>
                                        <option value="Denmark">Denmark</option>
                                        <option value="Djibouti">Djibouti</option>
                                        <option value="Dominica">Dominica</option>
                                        <option value="Dominican Republic">Dominican Republic</option>
                                        <option value="East Timor">East Timor</option>
                                        <option value="Ecuador">Ecuador</option>
                                        <option value="Egypt">Egypt</option>
                                        <option value="El Salvador">El Salvador</option>
                                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                                        <option value="Eritrea">Eritrea</option>
                                        <option value="Estonia">Estonia</option>
                                        <option value="Ethiopia">Ethiopia</option>
                                        <option value="Falkland Islands">Falkland Islands (Malvinas)</option>
                                        <option value="Faroe Islands">Faroe Islands</option>
                                        <option value="Fiji">Fiji</option>
                                        <option value="Finland">Finland</option>
                                        <option value="France">France</option>
                                        <option value="France Metropolitan">France, Metropolitan</option>
                                        <option value="French Guiana">French Guiana</option>
                                        <option value="French Polynesia">French Polynesia</option>
                                        <option value="French Southern Territories">French Southern Territories</option>
                                        <option value="Gabon">Gabon</option>
                                        <option value="Gambia">Gambia</option>
                                        <option value="Georgia">Georgia</option>
                                        <option value="Germany">Germany</option>
                                        <option value="Ghana">Ghana</option>
                                        <option value="Gibraltar">Gibraltar</option>
                                        <option value="Greece">Greece</option>
                                        <option value="Greenland">Greenland</option>
                                        <option value="Grenada">Grenada</option>
                                        <option value="Guadeloupe">Guadeloupe</option>
                                        <option value="Guam">Guam</option>
                                        <option value="Guatemala">Guatemala</option>
                                        <option value="Guinea">Guinea</option>
                                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                                        <option value="Guyana">Guyana</option>
                                        <option value="Haiti">Haiti</option>
                                        <option value="Heard and McDonald Islands">Heard and Mc Donald Islands</option>
                                        <option value="Holy See">Holy See (Vatican City State)</option>
                                        <option value="Honduras">Honduras</option>
                                        <option value="Hong Kong">Hong Kong</option>
                                        <option value="Hungary">Hungary</option>
                                        <option value="Iceland">Iceland</option>
                                        <option value="India">India</option>
                                        <option value="Indonesia">Indonesia</option>
                                        <option value="Iran">Iran (Islamic Republic of)</option>
                                        <option value="Iraq">Iraq</option>
                                        <option value="Ireland">Ireland</option>
                                        <option value="Israel">Israel</option>
                                        <option value="Italy">Italy</option>
                                        <option value="Jamaica">Jamaica</option>
                                        <option value="Japan">Japan</option>
                                        <option value="Jordan">Jordan</option>
                                        <option value="Kazakhstan">Kazakhstan</option>
                                        <option value="Kenya">Kenya</option>
                                        <option value="Kiribati">Kiribati</option>
                                        <option value="Democratic People's Republic of Korea">Korea, Democratic People's Republic of</option>
                                        <option value="Korea">Korea, Republic of</option>
                                        <option value="Kuwait">Kuwait</option>
                                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                                        <option value="Lao">Lao People's Democratic Republic</option>
                                        <option value="Latvia">Latvia</option>
                                        <option value="Lebanon" >Lebanon</option>
                                        <option value="Lesotho">Lesotho</option>
                                        <option value="Liberia">Liberia</option>
                                        <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                        <option value="Liechtenstein">Liechtenstein</option>
                                        <option value="Lithuania">Lithuania</option>
                                        <option value="Luxembourg">Luxembourg</option>
                                        <option value="Macau">Macau</option>
                                        <option value="Macedonia">Macedonia, The Former Yugoslav Republic of</option>
                                        <option value="Madagascar">Madagascar</option>
                                        <option value="Malawi">Malawi</option>
                                        <option value="Malaysia">Malaysia</option>
                                        <option value="Maldives">Maldives</option>
                                        <option value="Mali">Mali</option>
                                        <option value="Malta">Malta</option>
                                        <option value="Marshall Islands">Marshall Islands</option>
                                        <option value="Martinique">Martinique</option>
                                        <option value="Mauritania">Mauritania</option>
                                        <option value="Mauritius">Mauritius</option>
                                        <option value="Mayotte">Mayotte</option>
                                        <option value="Mexico">Mexico</option>
                                        <option value="Micronesia">Micronesia, Federated States of</option>
                                        <option value="Moldova">Moldova, Republic of</option>
                                        <option value="Monaco">Monaco</option>
                                        <option value="Mongolia">Mongolia</option>
                                        <option value="Montserrat">Montserrat</option>
                                        <option value="Morocco">Morocco</option>
                                        <option value="Mozambique">Mozambique</option>
                                        <option value="Myanmar">Myanmar</option>
                                        <option value="Namibia">Namibia</option>
                                        <option value="Nauru">Nauru</option>
                                        <option value="Nepal">Nepal</option>
                                        <option value="Netherlands">Netherlands</option>
                                        <option value="Netherlands Antilles">Netherlands Antilles</option>
                                        <option value="New Caledonia">New Caledonia</option>
                                        <option value="New Zealand">New Zealand</option>
                                        <option value="Nicaragua">Nicaragua</option>
                                        <option value="Niger">Niger</option>
                                        <option value="Nigeria" selected>Nigeria</option>
                                        <option value="Niue">Niue</option>
                                        <option value="Norfolk Island">Norfolk Island</option>
                                        <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                        <option value="Norway">Norway</option>
                                        <option value="Oman">Oman</option>
                                        <option value="Pakistan">Pakistan</option>
                                        <option value="Palau">Palau</option>
                                        <option value="Panama">Panama</option>
                                        <option value="Papua New Guinea">Papua New Guinea</option>
                                        <option value="Paraguay">Paraguay</option>
                                        <option value="Peru">Peru</option>
                                        <option value="Philippines">Philippines</option>
                                        <option value="Pitcairn">Pitcairn</option>
                                        <option value="Poland">Poland</option>
                                        <option value="Portugal">Portugal</option>
                                        <option value="Puerto Rico">Puerto Rico</option>
                                        <option value="Qatar">Qatar</option>
                                        <option value="Reunion">Reunion</option>
                                        <option value="Romania">Romania</option>
                                        <option value="Russia">Russian Federation</option>
                                        <option value="Rwanda">Rwanda</option>
                                        <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                        <option value="Saint LUCIA">Saint LUCIA</option>
                                        <option value="Saint Vincent">Saint Vincent and the Grenadines</option>
                                        <option value="Samoa">Samoa</option>
                                        <option value="San Marino">San Marino</option>
                                        <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                        <option value="Saudi Arabia">Saudi Arabia</option>
                                        <option value="Senegal">Senegal</option>
                                        <option value="Seychelles">Seychelles</option>
                                        <option value="Sierra">Sierra Leone</option>
                                        <option value="Singapore">Singapore</option>
                                        <option value="Slovakia">Slovakia (Slovak Republic)</option>
                                        <option value="Slovenia">Slovenia</option>
                                        <option value="Solomon Islands">Solomon Islands</option>
                                        <option value="Somalia">Somalia</option>
                                        <option value="South Africa">South Africa</option>
                                        <option value="South Georgia">South Georgia and the South Sandwich Islands</option>
                                        <option value="Span">Spain</option>
                                        <option value="SriLanka">Sri Lanka</option>
                                        <option value="St. Helena">St. Helena</option>
                                        <option value="St. Pierre and Miguelon">St. Pierre and Miquelon</option>
                                        <option value="Sudan">Sudan</option>
                                        <option value="Suriname">Suriname</option>
                                        <option value="Svalbard">Svalbard and Jan Mayen Islands</option>
                                        <option value="Swaziland">Swaziland</option>
                                        <option value="Sweden">Sweden</option>
                                        <option value="Switzerland">Switzerland</option>
                                        <option value="Syria">Syrian Arab Republic</option>
                                        <option value="Taiwan">Taiwan, Province of China</option>
                                        <option value="Tajikistan">Tajikistan</option>
                                        <option value="Tanzania">Tanzania, United Republic of</option>
                                        <option value="Thailand">Thailand</option>
                                        <option value="Togo">Togo</option>
                                        <option value="Tokelau">Tokelau</option>
                                        <option value="Tonga">Tonga</option>
                                        <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                        <option value="Tunisia">Tunisia</option>
                                        <option value="Turkey">Turkey</option>
                                        <option value="Turkmenistan">Turkmenistan</option>
                                        <option value="Turks and Caicos">Turks and Caicos Islands</option>
                                        <option value="Tuvalu">Tuvalu</option>
                                        <option value="Uganda">Uganda</option>
                                        <option value="Ukraine">Ukraine</option>
                                        <option value="United Arab Emirates">United Arab Emirates</option>
                                        <option value="United Kingdom">United Kingdom</option>
                                        <option value="United States">United States</option>
                                        <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                        <option value="Uruguay">Uruguay</option>
                                        <option value="Uzbekistan">Uzbekistan</option>
                                        <option value="Vanuatu">Vanuatu</option>
                                        <option value="Venezuela">Venezuela</option>
                                        <option value="Vietnam">Viet Nam</option>
                                        <option value="Virgin Islands (British)">Virgin Islands (British)</option>
                                        <option value="Virgin Islands (U.S)">Virgin Islands (U.S.)</option>
                                        <option value="Wallis and Futana Islands">Wallis and Futuna Islands</option>
                                        <option value="Western Sahara">Western Sahara</option>
                                        <option value="Yemen">Yemen</option>
                                        <option value="Serbia">Serbia</option>
                                        <option value="Zambia">Zambia</option>
                                        <option value="Zimbabwe">Zimbabwe</option>
                                    </select>
        </div>
        <div className="w-full">
          <label className="text-[12px] text-[#0A2E04]">Phone</label>
          <input
            type="tel"
            className="border border-[#1DB459] text-[#333333] text-sm block w-full p-3"
            placeholder="Enter Phone Number"
            name="phone"
            value={userData.phone}
            onChange={handleChange}
            required
          />
        </div>
        <div className="w-full">
          <label className="text-[12px] text-[#0A2E04]">Email</label>
          <input
            type="email"
            className="border border-[#1DB459] text-[#333333] text-sm block w-full p-3"
            placeholder="Enter Email"
            name="email"
            value={userData.email}
            onChange={handleChange}
            required
          />
        </div>
    

        <div className="w-full">
          <label className="text-[12px] text-[#0A2E04]">Gender</label>
          <select
            name="gender"
            value={userData.gender}
            onChange={handleChange}
            className="border border-[#1DB459] text-[#333333] text-sm block w-full p-3"
            required
          >
            <option value="">Select Gender</option>
            <option value="female">Female</option>
            <option value="male">Male</option>
            <option value="others">Others</option>
          </select>
        </div>
      </div>

      {/* Academic Background */}
      <h3 className="mt-5 text-[#1DB459] mb-6 font-medium text-[20px]">
        ACADEMIC BACKGROUND
      </h3>
      <div className="grid md:grid-cols-2 gap-5">
        {Object.keys(userData.academic_background).map((key) => (
          <div className="flex items-center" key={key}>
            <input
              type="checkbox"
              name={`academic_background.${key}`}
              checked={userData.academic_background[key]}
              onChange={handleChange} 
              className="w-5 h-5 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 focus:ring-2"
            />
            <label className="ml-2 text-gray-700 font-medium">
              {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, " $1")}
            </label>
          </div>
        ))}
      </div>

      {/* Documents */}
      <h3 className="mt-5 text-[#1DB459] mb-6 font-medium text-[20px]">
      SELECT THE LIST OF THE ACADEMIC CREDENTIALS YOU HAVE.
      </h3>
      <div className="grid md:grid-cols-3 gap-5">
        {Object.keys(userData.current_document).map((key) => (
          <div className="flex items-center" key={key}>
            <input
              type="checkbox"
              name={`current_document.${key}`}
              checked={userData.current_document[key]}
              onChange={handleChange}
              className="w-5 h-5 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 focus:ring-2"
            />
            <label className="ml-2 text-gray-700 font-medium">
              {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, " $1")}
            </label>
          </div>
        ))}
      </div>

      {/* Submit */}
      <div className="flex justify-start mb-10 mt-5">
        <button
          type="submit"
          // onClick={onOpenPaymentModal}
          className="text-white bg-[#1DB459] hover:bg-[#05401C] focus:ring-4 font-medium text-[16px] px-6 py-2.5 mr-2"
        >
          Submit Application
        </button>
        <div className="flex justify-center">
          <MutatingDots
            height="70"
            width="100"
            color="#4fa94d"
            secondaryColor="#4fa94d"
            radius="9.5"
            ariaLabel="mutating-dots-loading"
            visible={loader}
          />
        </div>
      </div>
    </form>

            </div>
          </div>
        </div>

        <Modal open={open} onClose={navigatetoLithuania} center>
          <div className="md:max-w-md  body-font font-poppins">
            <h2 className="text-center text-[#1DB459] font-bold md:text-[26px] text-[22px]">
             Application Processing
            </h2>

            <p className="text-center text-[#838383] md:text-[14px] text-[12px]">
              By seeking assistance, you acknowledge and agree to the payment of
              a one-time <span className="font-bold">non-refundable application processing fee</span>
              of{" "}
            </p>
            <h2 className="text-center text-[#1DB459] font-bold md:text-[26px] text-[22px] pb-4">
              {" "}
              €350.00
            </h2>
            <p className=" text-[#838383] md:text-[14px] text-[12px]">
              <span className="text-[#F04946]">DISCLAIMER:</span> Please be aware that any information provided will be used to verify your identity for future referencing and also for your Visa and Residence Permit processing.
            </p>
            <p className="text-[#838383] md:text-[14px] text-[12px] pt-2">
            Also note that AFRIPROEDU does not have the authority to grant admissions to students or influence any school's decision to accept them. Our role is to enhance your chances by carefully reviewing your documents to ensure they meet the eligibility criteria set by the school.
            </p>

            <div className="flex justify-center mt-10 mb-4">
              <button
                onClick={onCloseModal}
                className="bg-[#1DB459] text-white  md:w-[300px] w-full flex justify-center px-5 rounded-full py-3"
              >
                Yes Please
              </button>{" "}
            </div>
            <div className="flex justify-center mt-10 mb-4">
              <Link
                to={"/study-in-lithuania/study-program"}
                className="bg-[#EEEEEE]   md:w-[300px] w-full flex justify-center px-5 rounded-full py-3"
              >
                No, Thank you
              </Link>{" "}
            </div>
          </div>
        </Modal>




        <Modal open={openPayment} onClose={onClosePaymentModal} center styles={{
                modal: {
                    borderRadius: '15px', // Add border-radius here
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Optional: Add shadow
                },
            }}>

                <div className=" flex justify-start  mt-1">
                    <h4 className="tex-[#000000] text-[16px]  font-[500]">
                        Payment
                    </h4>

                </div>
                <div className="md:w-[460px] body-font font-poppins">

                    
                   

                    {/* Payment Amount */}   
                    <div className="bg-[#F7F7F7] rounded-[8px] pt-3 pb-1 text-center mt-4 mb-4">
                        <p className="text-[14px] text-[#5A5A5A]">Payment</p>
                        <p className="text-[28px] font-semibold text-[#00B07B]">€350</p>
                    </div>

                    

                    {/* foreign page */}
                    <div>
                        <div className=" mt-[16px] mb-6">
                            <div className='border border-[#D6D9DC] rounded-[10px] px-2 py-2'>

                                <div className='flex justify-between'>
                                    <h3 className='font-semibold text-[20px] text-[000]'>Summary</h3>
                                    
                                </div>

                                {/* <div className='flex justify-between bg-[#F7F7F7] py-1.5 px-1.5 rounded-md mt-[10px]'>
                                    <h4 className='lg:text-[16px] text-[13px] text-[#5A5A5A] font-[400]'>Fee</h4>
                                    <h3 className='text-[#525252] lg:text-[14px] text-[12px] font-[500]'> $22</h3>
                                </div> */}

                                

                                <div className='flex justify-between bg-[#F7F7F7] py-1.5 px-1.5 rounded-md mt-[7px]'>
                                    <h4 className='lg:text-[16px] text-[13px] text-[#5A5A5A] font-[400]'>You will transfer</h4>
                                    <h3 className='text-[#525252] lg:text-[14px] text-[12px] font-[500]'>€350 </h3>
                                </div>

                            </div>
                        </div>

                        {/* Bank Details */}
                        <div className="space-y-3 mb-8">
                            <div className="flex justify-between items-center">
                                <span className="text-[#727272] text-[14px]">Account Name</span>
                                <span className="font-medium text-[14px]">AFRIPRO OY</span>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-[#727272] text-[14px]">Account Number</span>
                                <div className="flex items-center gap-2">
                                    <span className="font-medium text-[14px]">8312818556</span>
                                    <div className="relative">
                                        <button
                                            onClick={() => copyToClipboard('8312818556', 'acct')}
                                            className="text-[#000000] hover:text-gray-800 pt-0.5"
                                        >
                                            <TbCopy />

                                        </button>
                                        {copiedField === 'acct' && (
                                            <div className="absolute -top-5 -left-1/4 transform -translate-x-1/2 bg-gray-800 text-white text-[10px] py-1 px-2 rounded whitespace-nowrap">
                                                Copied!
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-[#727272] text-[14px]">Account Type</span>
                                <div className="flex items-center gap-2">
                                    <span className="font-medium text-[14px]">Checking</span>
                                    <div className="relative">
                                        <button
                                            onClick={() => copyToClipboard('Checking', 'type')}
                                            className="text-[#000000] hover:text-gray-800 pt-0.5"
                                        >
                                            <TbCopy />

                                        </button>
                                        {copiedField === 'type' && (
                                            <div className="absolute -top-5 -left-1/4 transform -translate-x-1/2 bg-gray-800 text-white text-[10px] py-1 px-2 rounded whitespace-nowrap">
                                                Copied!
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-[#727272] text-[14px]">Bank Name</span>
                                <div className="flex items-center gap-2">
                                    <span className="font-medium text-[14px]">WISE</span>
                                    <div className="relative">
                                        <button
                                            onClick={() => copyToClipboard('WISE', 'bank')}
                                            className="text-[#000000] hover:text-gray-800 pt-0.5"
                                        >
                                            <TbCopy />

                                        </button>
                                        {copiedField === 'bank' && (
                                            <div className="absolute -top-5 -left-1/4 transform -translate-x-1/2 bg-gray-800 text-white text-[10px] py-1 px-2 rounded whitespace-nowrap">
                                                Copied!
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-[#727272] text-[14px]">Routing Number</span>
                                <div className="flex items-center gap-2">
                                    <span className="font-medium text-[14px]">026073150</span>
                                    <div className="relative">
                                        <button
                                            onClick={() => copyToClipboard('026073150', 'routing')}
                                            className="text-[#000000] hover:text-gray-800 pt-0.5"
                                        >
                                            <TbCopy />

                                        </button>
                                        {copiedField === 'routing' && (
                                            <div className="absolute -top-5 -left-1/4 transform -translate-x-1/2 bg-gray-800 text-white text-[10px] py-1 px-2 rounded whitespace-nowrap">
                                                Copied!
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-[#727272] text-[14px]">Swift/BIC</span>
                                <div className="flex items-center gap-2">
                                    <span className="font-medium text-[14px]">CMFGUS33</span>
                                    <div className="relative">
                                        <button
                                            onClick={() => copyToClipboard('CMFGUS33', 'swift')}
                                            className="text-[#000000] hover:text-gray-800 pt-0.5"
                                        >
                                            <TbCopy />

                                        </button>
                                        {copiedField === 'swift' && (
                                            <div className="absolute -top-5 -left-1/4 transform -translate-x-1/2 bg-gray-800 text-white text-[10px] py-1 px-2 rounded whitespace-nowrap">
                                                Copied!
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-[#727272] text-[14px]">Bank Address</span>
                                <div className="flex items-center gap-2">
                                    <span className="font-medium text-[14px]">Community Federal Savings Bank, <br />89-16 Jamaica Ave, Woodhaven, <br />NY, 11421, United State</span>
                                    <div className="relative">
                                        <button
                                            onClick={() => copyToClipboard('Community Federal Savings Bank,89-16 Jamaica Ave, Woodhaven,NY, 11421, United State', 'address')}
                                            className="text-[#000000] hover:text-gray-800 pt-0.5"
                                        >
                                            <TbCopy />

                                        </button>
                                        {copiedField === 'address' && (
                                            <div className="absolute -top-5 -left-1/4 transform -translate-x-1/2 bg-gray-800 text-white text-[10px] py-1 px-2 rounded whitespace-nowrap">
                                                Copied!
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-between items-center">
                                <span className="text-[#727272] text-[14px]">Recipient Address</span>
                                <div className="flex items-center gap-2">
                                    <span className="font-medium text-[14px]">Lotankatu 3, Espoo, 02650, Finland</span>
                                    <div className="relative">
                                        <button
                                            onClick={() => copyToClipboard('Lotankatu 3, Espoo, 02650, Finland', 'reciAddress')}
                                            className="text-[#000000] hover:text-gray-800 pt-0.5"
                                        >
                                            <TbCopy />

                                        </button>
                                        {copiedField === 'reciAddress' && (
                                            <div className="absolute -top-5 -left-1/4 transform -translate-x-1/2 bg-gray-800 text-white text-[10px] py-1 px-2 rounded whitespace-nowrap">
                                                Copied!
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button onClick={onClosePaymentModal} className="w-full bg-[#00B07B] text-white py-3 rounded-lg hover:bg-emerald-600 transition-colors">
                            I have make payment
                        </button>
                    </div>
</div>
                
            </Modal>





    <Modal open={openPaymentProof} onClose={onClosePaymentProofModal} center styles={{
                modal: {
                    borderRadius: '15px', // Add border-radius here
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', // Optional: Add shadow
                },
            }}>
                <div className='md:w-[350px] w-[300px]'>
                    <div className=" flex justify-start mt-7">
                        <h4 className="tex-[#000000] md:text-[18px] text-[14px] font-bold">
                            Proof of Payment
                        </h4>

                    </div>
                    <div className='flex  mt-5'>
                        <p className='text-[14px] text-[#898989] '>Kindly send your receipt to any of the following platform to confirm your payment  </p>
                    </div>

                    <div className=' mt-5'>
                        <div className='flex gap-2'>
                            <MdOutlineMail size={20} />
                            <a href="mailto:hello@afriproedu.com" className=' underline underline-offset-2 text-[14px]'>hello@afriproedu.com</a>
                        </div>

                        <div className='flex gap-2 mt-3'>
                            <TbBrandWhatsapp size={20} />
                            <NavLink to="https://api.whatsapp.com/send?phone=2349047248430" target='_blank'>
                                <span className=' underline underline-offset-2 text-[14px]'>+234 904 724 8430</span>
                            </NavLink>
                        </div>
                    </div>
                    <div>

                        <div className='mt-5 flex justify-center'>
                            <button onClick={onClosePaymentProofModal} className="w-full bg-[#00B07B] text-white py-3 rounded-lg hover:bg-emerald-600 transition-colors">
                                Okay
                            </button>

                        </div>
                    </div>
                </div>

            </Modal>
      </div>
   
   


   
   
   
    </div>
  );
};

export default StudyProgramOverview;

// import React, { useState } from "react";
// import axios from "axios";
// // import './PaymentForm.css';

// const PaymentForm = () => {
//   const [formData, setFormData] = useState({
//     firstname: "",
//     lastname: "",
//     email: "",
//     country: "",
//     phone: "",
//     gender: "",
//     academy_background: "",
//     current_document: "",
//   });

//   const handleChange = (e:any) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = async (e:any) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post("http://127.0.0.1:5465/api/payment/initialize", formData);
//       if (response.data.status === 'success') {
//         window.location.href = response.data.payment_link;  // Redirect to the payment link
//       } else {
//         alert("Payment failed. Please try again.");
//       }
//     } catch (error:any) {
//       console.error(error.response?.data || error.message); // Logs error details
//       alert("Payment failed. Please try again.");
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <h2>Event Payment Form</h2>
      
//       {/* First Name */}
//       <label>
//         First Name:
//         <input
//           type="text"
//           name="firstname"
//           value={formData.firstname}
//           onChange={handleChange}
//           required
//         />
//       </label>
      
//       {/* Last Name */}
//       <label>
//         Last Name:
//         <input
//           type="text"
//           name="lastname"
//           value={formData.lastname}
//           onChange={handleChange}
//           required
//         />
//       </label>
      
//       {/* Email */}
//       <label>
//         Email:
//         <input
//           type="email"
//           name="email"
//           value={formData.email}
//           onChange={handleChange}
//           required
//         />
//       </label>
      
//       {/* Country */}
//       <label>
//         Country:
//         <input
//           type="text"
//           name="country"
//           value={formData.country}
//           onChange={handleChange}
//           required
//         />
//       </label>

//       {/* Gender */}
//       <label>
//         Gender:
//         <input
//           type="text"
//           name="gender"
//           value={formData.gender}
//           onChange={handleChange}
//           required
//         />
//       </label>
      
//       {/* Phone */}
//       <label>
//         Phone:
//         <input
//           type="text"
//           name="phone"
//           value={formData.phone}
//           onChange={handleChange}
//           required
//         />
//       </label>

//       {/* Academic Background */}
//       <label>
//         Academic Background:
//         <input
//           type="text"
//           name="academy_background"
//           value={formData.academy_background}
//           onChange={handleChange}
//         />
//       </label>
      
//       {/* Current Document */}
//       <label>
//         Current Document:
//         <input
//           type="text"
//           name="current_document"
//           value={formData.current_document}
//           onChange={handleChange}
//         />
//       </label>
      
//       <button type="submit">Submit Payment</button>
//     </form>
//   );
// };

// export default PaymentForm;