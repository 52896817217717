import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import { SvgElement, icontypesEnum } from "../assets/svgElement";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import hero from "../../img/new-course-bg.png";
import hero2 from "../../img/paramed.jpg";
import bgbus from "../../img/bgbus.jpg";
import bgmech from "../../img/bgmecha.jpg";
import bgtour from "../../img/bgtour.jpg";
import bgsust from "../../img/bgsust.jpg";
import bgsustSo from "../../img/bgsustSo.jpg";
import bgInno from "../../img/bgInno.jpg";
import bgCare from "../../img/bgCare.jpg";
import bgtechTra from "../../img/bgtechTra.jpg";
import bgcircular from "../../img/bgcircular.jpg";
import bgculture from "../../img/bgculture.jpg";
import bgit from "../../img/bgit.webp";
import herosmall from "../../img/new-course-small.png";
import { AuthApis } from "../../apis/authApis";
import { useParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import configs from "../../configs";
import Footer from "../Footer";

//@ts-ignore
import DOMPurify from "dompurify";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import mastersProgram from "../../Components/assets/masters.json";
import degreeProgram from "../../Components/assets/degree.json";
import diplomaProgram from "../../Components/assets/diploma.json";

const CourseDetails = () => {
  const params: any = useParams();
  let partnerId: string = params?.courseId;
  const navigate = useNavigate();
  const location = useLocation(); // Access the location object
  const { category } = location.state || {};
  console.log(category)
  console.log(partnerId)
  const [courseDetails, setCourseDetails] = React.useState<any>("");
  const [course, setCourse] = useState<any>(null);

  // console.log(mastersProgram)
  // console.log(degreeProgram)
  // Find the course by matching the courseName from the URL with the course name in the JSON

  // Use the category to conditionally fetch data
  useEffect(() => {
    if (category) {
      // Direct navigation with state
      const foundCourse =
        category === "degree program"
          ? degreeProgram.courses.find(
              (course: any) =>
                course.name.replace(/ /g, "-").toLowerCase() ===
              partnerId?.toLowerCase()
            )
          : category === "diploma program" 
          ? diplomaProgram.courses.find(
            (course: any) =>
              course.name.replace(/ /g, "-").toLowerCase() ===
            partnerId?.toLowerCase()
          ) : mastersProgram.courses.find(
              (course: any) =>
                course.name.replace(/ /g, "-").toLowerCase() ===
                partnerId?.toLowerCase()
            );
      setCourse(foundCourse || null);
    } else {
      // Fallback: Try to find the course across all programs
      const foundCourse =
        degreeProgram.courses.find(
          (course: any) =>
            course.name.replace(/ /g, "-").toLowerCase() ===
          partnerId?.toLowerCase()
        ) ||
        diplomaProgram.courses.find(
          (course: any) =>
            course.name.replace(/ /g, "-").toLowerCase() ===
          partnerId?.toLowerCase()
 ) || mastersProgram.courses.find(
          (course: any) =>
            course.name.replace(/ /g, "-").toLowerCase() ===
          partnerId?.toLowerCase()
        );
      setCourse(foundCourse || null);
    }
  }, [partnerId, category, degreeProgram, mastersProgram]);

  if (!course) {
    return <div>Course not found!</div>;
  }


  // console.log(course);

  // React.useEffect(() => {
  //   if (!userLoginData.token) {
  //     setIsLogin(false);
  //   }

  //   if (userLoginData.token) {
  //     setIsLogin(true);
  //   }
  // }, []);
  // React.useEffect(() => {
  //   AuthApis.getSinglePartnerCourse(partnerId?.replace(/-/g, " "))
  //     .then((response: AxiosResponse<any>) => {
  //       if (response?.data) {
  //         setCourseDetails(response?.data?.data);

  //         AuthApis.getSinglePartnerSchoolByName(
  //           response?.data?.data?.school
  //         ).then((response: AxiosResponse<any>) => {
  //           if (response?.data) {
  //             setSchooldata(response?.data?.data);
  //           }
  //         });
  //       }
  //     })
  //     .catch(function (error) {
  //       // handle error
  //     });
  // }, []);

  const priceval = courseDetails?.product_cost;
  const options = { maximumFractionDigits: 2 };
  const formattedNumber = Intl.NumberFormat("en-US", options).format(priceval);

  // const window = new JSDOM('').window;
  // const purify = DOMPurify(window);

  return (
    <>
      <Navbar />
      {/* <!-- Start block --> */}
      <section className="hidden md:block md:pt-20 relative">
        <div
          style={{
            backgroundImage: `url(${course?.name === "Paramedic Nurse" ? hero2 : course?.name === "International Business" ? bgbus : course?.name === "Industrial Mechanical Engineering" ? bgmech : course?.name === "Industrial Information Technology" ? bgit : course?.name === "Tourism and Hospitality Management" ? bgtour : course?.name === "Sustainable Construction Technology" ? bgsust : course?.name === "Sustainable Solutions Engineering" ? bgsustSo :  course?.name === "Business Innovation Culture and Creativity(Online Studies)" ? bgInno : course?.name === "Emergency Care Systems Development and Leadership(Online Studies)" ? bgCare : course?.name === "Technological Transformation Leadership(Online Program)" ? bgtechTra : course?.name === "Circular Economy Solutions(Online Studies)" ? bgcircular : course?.name === "Culture and Arts, Design for the Planet(Online Studies)" ? bgculture : hero})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            position: "relative"
          }}
          className="md:bg-cover relative bg-contain mt-[75px] md:mt-0 sm:px-16 md:px-14 px-6 md:pb-20 md:pt-20 pt-2  "
        >
            {/* Black overlay for "Paramedic Nurse" */}
  {(course?.name === "Paramedic Nurse" || course?.name === "International Business" || course?.name === "Industrial Information Technology" || course?.name === "Industrial Mechanical Engineering" || course?.name === "Tourism and Hospitality Management"|| course?.name === "Sustainable Construction Technology" || course?.name === "Sustainable Solutions Engineering" || course?.name === "Business Innovation Culture and Creativity(Online Studies)" || course?.name === "Emergency Care Systems Development and Leadership(Online Studies)" || course?.name === "Technological Transformation Leadership(Online Program)" || course?.name === "Circular Economy Solutions(Online Studies)" || course?.name === "Culture and Arts, Design for the Planet(Online Studies)") && (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Black with 50% opacity
        // zIndex: 1, // Ensures overlay is above the background but below content
      }}
    ></div>
  )}
          <div className=" text-white relative z-10 md:pt-40 pt-64 md:pb-5 pb-5">
            {/* <img src={configs.partnerSchools + schoolData?.logo} alt={schoolData?.logo} className='h-9 md:h-16' /> */}
            <h1   style={{
        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)", // Subtle shadow for visibility
      }}
       className="md:text-[62px] z-0 leading-[60px] flex justify-center text-3xl text-center font-bold md:mt-[12px]">
              {course?.name}
            </h1>
            {/* <NavLink to={`/school-details/${schoolData?.id}`}>
                            <h1 className="md:text-2xl text-base font-bold md:mt-[12px]">
                                {schoolData?.school_name}
                            </h1>
                        </NavLink>
                        <p className='md:text-base text-sm font-semibold max-w-[430px] md:mt-[10px] capitalize'>{courseDetails?.category}</p> */}
          </div>

          <div
            className="h-40 absolute top-2 text-white text-[32px] rounded-lg left-6 shadow-lg md:h-40 cursor-pointer"
            onClick={() => navigate(-1)}
          >
            {" "}
            ←{" "}
          </div>

          {/* <img src={schoolData?.logo_url} alt={schoolData?.logo} className='h-40 absolute bottom-[-50px] rounded-[26px] md:left-28 left-5 shadow-lg md:h-40' /> */}
        </div>
      </section>
      {/* <!-- End block --> */}

      {/* <!-- Mobile section --> */}
      {/* <!-- Start block --> */}
      <section className="block md:hidden md:pt-20 relative">
        <div
          style={{
            backgroundImage: `url(${course?.name === "Paramedic Nurse" ? hero2 : course?.name === "International Business" ? bgbus : course?.name === "Industrial Mechanical Engineering" ? bgmech : course?.name === "Tourism and Hospitality Management" ? bgtour : course?.name === "Sustainable Construction Technology" ? bgsust : course?.name === "Sustainable Solutions Engineering" ? bgsustSo : course?.name === "Business Innovation Culture and Creativity(Online Studies)" ? bgInno : course?.name === "Emergency Care Systems Development and Leadership(Online Studies)" ? bgCare : course?.name === "Technological Transformation Leadership(Online Program)" ? bgtechTra : course?.name === "Circular Economy Solutions(Online Studies)" ? bgcircular : course?.name === "Culture and Arts, Design for the Planet(Online Studies)" ? bgculture : herosmall})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
          className="md:bg-cover relative bg-contain mt-[75px] md:mt-0 sm:px-16 md:px-14 px-6 md:pb-20 md:pt-20 pt-2  flex items-start"
        >
           {(course?.name === "Paramedic Nurse" || course?.name === "International Business" || course?.name === "Industrial Information Technology" || course?.name === "Industrial Mechanical Engineering" || course?.name === "Sustainable Construction Technology" || course?.name === "Sustainable Solutions Engineering" || course?.name === "Business Innovation Culture and Creativity(Online Studies)" || course?.name === "Emergency Care Systems Development and Leadership(Online Studies)" || course?.name === "Technological Transformation Leadership(Online Program)" || course?.name === "Circular Economy Solutions(Online Studies)" || course?.name === "Culture and Arts, Design for the Planet(Online Studies)") && (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Black with 50% opacity
        // zIndex: 1, // Ensures overlay is above the background but below content
      }}
    ></div>
  )}
          <div className=" text-white relative md:pt-40 pt-64 md:pb-5 pb-5">
          <h1   style={{
        textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)", // Subtle shadow for visibility
      }}
       className="md:text-[62px] z-0 leading-[60px] flex justify-center text-3xl text-center font-bold md:mt-[12px]">
              {course?.name}
            </h1>
          </div>

          <div
            className="h-40 absolute top-2 text-white text-[32px] rounded-lg left-6 shadow-lg cursor-pointer"
            onClick={() => navigate(-1)}
          >
            {" "}
            ←{" "}
          </div>

          {/* <img src={schoolData?.logo_url} alt={schoolData?.logo} className='h-[130px] absolute bottom-[-70px] rounded-[10px] md:left-28 left-5 shadow-lg  mb-5' /> */}
        </div>
      </section>
      {/* <!-- Start block --> */}

      {/* <!-- Start block --> */}
      <div className=" sm:px-16 px-6 md:flex md:justify-center items-start md:pt-5 pt-10">
        <div className="xl:max-w-[1200px] w-full mx-auto">
          <div className="sm:py-16 py-6 relative">
            <div className="md:grid md:grid-cols-12 gap-6">
              <div className="md:col-span-4 mb-9 w-full">
                <div className="w-full">
                  <div className="border w-full rounded-t-[10px] ">
                    <div className="flex justify-center rounded-t-[10px] py-2 bg-[#1DB459]">
                      <h3 className="text-white md:text-[20px] text-[16px]">
                        Program features
                      </h3>
                    </div>

                    {course?.program_details?.service_fee && (
     <div className="border-b">
     <div className="flex gap-6 px-4 py-2">
       <div>
         {/* {course?.available_programmes.map((data: any) => ( */}
         <div className="text-[#1DB459] text-[32px] font-[600]">
           {course?.program_details?.service_fee}
         </div>
         {/* ))} */}
       </div>

       <div className="">
         <h4>Processing fee</h4>
       </div>
     </div>
   </div>
                    )}
               
               {course?.program_details?.duration && (
    <div className="border-b">
    <div className="flex gap-2 px-4 py-4">
      <div>
        {/* {course?.available_programmes.map((data: any) => ( */}
        <div className="text-[#000000] text-[16px] ">
          Duration: {course?.program_details?.duration}
        </div>
        {/* ))} */}
      </div>
    </div>
  </div>
               )}
                

                    <div className="border-b">
                      <div className="flex gap-2 px-4 py-4">
                        <div>
                          {/* {course?.available_programmes.map((data: any) => ( */}
                          <div className="text-[#000000] text-[16px] ">
                            Registration start:{" "}
                            {course?.program_details?.registration?.start}
                          </div>
                          {/* ))} */}
                        </div>
                      </div>
                    </div>

                    <div className="border-b">
                      <div className="flex gap-2 px-4 py-4">
                        <div>
                          {/* {course?.available_programmes.map((data: any) => ( */}
                          <div className="text-[#000000] text-[16px] ">
                            Registration ends:{" "}
                            {course?.program_details?.registration?.end}
                          </div>
                          {/* ))} */}
                        </div>
                      </div>
                    </div>

                    <div className="border-b">
                      <div className="flex gap-2 px-4 py-4">
                        <div>
                          {/* {course?.available_programmes.map((data: any) => ( */}
                          <div className="text-[#000000] text-[16px] ">
                            School resumption:{" "}
                            {course?.program_details?.resumption}
                          </div>
                          {/* ))} */}
                        </div>
                      </div>
                    </div>

                    <div className="border-b">
                      <div className="flex gap-2 px-4 py-4">
                        <div>
                          {/* {course?.available_programmes.map((data: any) => ( */}
                          <div className="text-[#000000] text-[16px] ">
                            Tuition fee: {course?.program_details?.tuition_fee}
                          </div>
                          {/* ))} */}
                        </div>
                      </div>
                    </div>

                    {/* {(course?.name === "Paramedic Nurse" || course?.name === "International Business" ) && ( */}
                      <>
  <div className="border-b">
  <div className="flex gap-2 px-4 py-4">
    <div>
      {/* {course?.available_programmes.map((data: any) => ( */}
      <div className="text-[#000000] text-[16px] ">
        Language of Instruction: English
      </div>
      {/* ))} */}
    </div>
  </div>
</div>
<div className="border-b">
  <div className="flex gap-2 px-4 py-4">
    <div>
      {/* {course?.available_programmes.map((data: any) => ( */}

      {category === "degree program" ? (
   <div className="text-[#000000] text-[16px] ">
   {course?.name === "Paramedic Nurse" ? (
     <div>
Scope of studies: 240 ECTS credits
       </div>
   ) : (course?.name === "Business Administration, International Business" || course?.name === "Bachelor of Hospitality Management" ) ? (
     <div>
     Scope of studies: 210 ECTS credits
            </div>
   ) : (
     <div>
     Scope of studies: 240 ECTS credits
            </div>
   )
 }  
     </div>
      ) : category === "diploma program" ? (
        <div>
           Interview date to be announced:  Interview is a simple English comprehension interview 

          </div>) 
      : (
        <div className="text-[#000000] text-[16px] ">
      120 ECTS credits
        {/* {course?.name === "Business Innovation Culture and Creativity(Online Studies)" ? (
          <div>
     Scope of studies: 90 ECTS credits
            </div>
        )  : (course?.name === "Technological Transformation Leadership(Online Program)" || course?.name === "Circular Economy Solutions(Online Studies)" || course?.name === "Culture and Arts, Design for the Planet(Online Studies)") ? 
        (
          <div>
          Scope of studies: 60 ECTS credits
                 </div>
        ): (
          <div>
          Scope of studies: 90 ECTS credits
                 </div>
        )
      }   */}
          </div>
      )}
   
      {/* ))} */}
    </div>
  </div>
</div>
<div className="border-b">
  <div className="flex gap-2 px-4 py-4">
    <div>
      {/* {course?.available_programmes.map((data: any) => ( */}
      {category === "degree program" ? (
      <div className="text-[#000000] text-[16px] ">
       Teaching time: Day time teaching
      </div>
      ) : category === "diploma program" ? (
        <div className="text-[#000000] text-[16px] ">
        Academic Requirements: Minimum high school diploma

       </div>
      ) : (
        <div className="text-[#000000] text-[16px] ">
        Teaching time: Day time teaching

       </div>
      )}
      {/* ))} */}
    </div>
  </div>
</div>

{category !== "degree program" && (
  <div className="border-b">
  <div className="flex gap-2 px-4 py-4">
    <div>
      {category === "diploma program" ? (
    <div className="text-[#000000] text-[16px] ">
    No Work Experience Needed: Accessible to everyone

   </div>
      ) : (
        <div className="text-[#000000] text-[16px] ">
        Form of teaching: Blended teaching

       </div>
      )}

      {/* ))} */}
    </div>
  </div>
</div>
)}
                </>   
               {/* )} */}

                    {category === "degree program" ? (
                      <NavLink
                        to={`/degree-application-form/${course?.available_programmes}`}
                        className=" w-full"
                      >
                        <button
                          type="button"
                          className=" hover:bg-[#1DB459] border border-[#1DB459] hover:text-white  rounded-[10px] md:text-[18px] text-[16px] px-6 min-w-full  py-3 shadow-md"
                        >
                          Get started
                        </button>
                      </NavLink>
                    ) : category === "diploma program" ? (
                      <NavLink
                      to={`/diploma-application-form/${course?.available_programmes}`}
                      className=" w-full"
                    >
                      <button
                        type="button"
                        className=" hover:bg-[#1DB459] border border-[#1DB459] hover:text-white  rounded-[10px] md:text-[18px] text-[16px] px-6 min-w-full  py-3 shadow-md"
                      >
                        Get started
                      </button>
                    </NavLink>
                    ) : (
                      <NavLink
                        to={`/masters-application-form/${course?.available_programmes}`}
                        className=" w-full"
                      >
                        <button
                          type="button"
                          className=" hover:bg-[#1DB459] border border-[#1DB459] hover:text-white rounded-[10px] md:text-[18px] text-[16px] px-6 min-w-full  py-3 shadow-md"
                        >
                          Get started
                        </button>
                      </NavLink>
                    )}
                  </div>
                </div>

                <div className="mt-6">
                  <div className="bg-white border rounded-[10px]">
                    <div className="flex justify-center border-b rounded-t-[10px] py-2 bg-[#1DB459]">
                      <h3 className="text-white md:text-[20px] text-[16px]">
                        Available Programs
                      </h3>
                    </div>

                    <div className=" py-6 px-4">
                      <div>
                        {course?.available_programmes?.map((data: any) => (
                          <div className=" pb-2 text-[16px] ">
                            - {data}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                {/* {course?.documents_needed && (
<div>
{category !== "degree program" && (
                  <div className="mt-6">
                    <div className="border rounded-[10px]">
                      <div className="flex justify-center border-b rounded-t-[10px] py-2 ">
                        <h3 className="text-[#1DB459] md:text-[20px] text-[16px]">
                          Document needed
                        </h3>
                      </div>

                      <div className=" py-6 px-4">
                        <h4 className="font-[600] text-[20px]">
                          For applicants with finished bachelor's degree
                        </h4>
                        <div>
                          {course?.documents_needed?.finished_bachelors_degree?.map(
                            (data: any) => (
                              <div className="pb-3 text-[16px] ">- {data}</div>
                            )
                          )}
                        </div>

                        <h4 className="font-[600] text-[20px] pt-6">
                          For applicants with unfinished bachelor's degree
                        </h4>
                        <div>
                          {course?.documents_needed?.unfinished_bachelors_degree?.map(
                            (data: any) => (
                              <div className="pb-3 text-[16px] ">- {data}</div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
  </div>
                )} */}
             

                {category === "degree program" ? (
                  <div className="mt-6">
                    {/* {course?.name === "Paramedic Nurse" ? ( */}
  <div className="border rounded-[10px]">
  <div className="flex justify-center border-b rounded-t-[10px] bg-[#1DB459] py-2 ">
    <h3 className="text-white text-center md:text-[20px] text-[16px]">
      Scholarships for Bachelor's studies
    </h3>
  </div>

  {/* {course?.eligibility?.sat_test ? (
    <div className=" py-6 px-4">
      <div>
        <div className="py-3  text-[16px] flex gap-2">
          -{" "}
          <h4>
          Applying for the 50% scholarship is possible after the first academic year. To be able to use the given option, you must be able to study full time during the first academic year - meaning, that you must
complete a minimum of 60 ECTS
credits of studies that are included in your personal study plan.
          </h4>
        </div>
      </div>
    </div>
  ) : ( */}
    
    <div className=" py-6 px-4">
      <div>
        <div className="py-3  text-[16px] flex gap-2">
      
          <h4>
          Early Bird scholarship  is 10 % of the Tuition fee. Scholarship is available for the students, who will:

          </h4>
         
        </div>
       <p>  - Accept the study place offered  within 7 days of notice of admission</p>
          <p>- Pay the tuition fee within 28 days of notice of admission          </p>
          <p>- Register as an attending student for the first academic year.</p>
      </div>
    </div>
    
  {/* )} */}
</div>
                    {/* ) : (
                      <div className="border rounded-[10px]">
                      <div className="flex justify-center border-b rounded-t-[10px] bg-[#1DB459] py-2 ">
                        <h3 className="text-white text-center md:text-[20px] text-[16px]">
                          Scholarships for Bachelor's studies
                        </h3>
                      </div>
                      {course?.eligibility?.sat_test ? (
                        <div className=" py-6 px-4">
                          <div>
                            <div className="py-3  text-[16px] flex gap-2">
                              -{" "}
                              <h4>
                                For the second academic year, 50% scholarships
                                will be granted for the students successfully
                                completing the studies on the first academic
                                year (min.of 60 ECTS credits)
                              </h4>
                            </div>
                          </div>
                    
                          <div>
                            <div className="py-3  text-[16px] flex gap-2">
                              -{" "}
                              <h4>
                                {" "}
                                For the third academic year, 50% Tuition Fee
                                Waiver will be granted for the students
                                successfully completing min.120 ECTS credits in
                                the first and second academic years.
                              </h4>
                            </div>
                          </div>
                    
                          <div>
                            <div className="py-3  text-[16px] flex gap-2">
                              -{" "}
                              <h4>
                                If the student continues their studies in a
                                Master's programme after completing their
                                Bachelor's studies, the tuition fee for the
                                first year of Master's studies is EUR 7,500
                              </h4>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className=" py-6 px-4">
                          <div>
                            <div className="py-3  text-[16px] flex gap-2">
                              -{" "}
                              <h4>
                                Scholarship Discount- 10% early bird discount
                                for students who accept offer within 7 days and
                                pay fees within 28 days of notice of admission.
                                This is only applicable to Direct Application
                                students. After 29 May 2025, scholarship
                                discount will no longer be available.
                              </h4>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    )} */}
                  
                  </div>
                ) : category === "diploma program" ? (
                  <div>

                    </div>
                ) : (
                  <div className="">
                     <div className="mt-6">
                    {/* {course?.name === "Paramedic Nurse" ? ( */}
  <div className="border rounded-[10px]">
  <div className="flex justify-center border-b rounded-t-[10px] bg-[#1DB459] py-2 ">
    <h3 className="text-white text-center md:text-[20px] text-[16px]">
      Scholarships 
    </h3>
  </div>

  {/* {course?.eligibility?.sat_test ? (
    <div className=" py-6 px-4">
      <div>
        <div className="py-3  text-[16px] flex gap-2">
          -{" "}
          <h4>
          Applying for the 50% scholarship is possible after the first academic year. To be able to use the given option, you must be able to study full time during the first academic year - meaning, that you must
complete a minimum of 60 ECTS
credits of studies that are included in your personal study plan.
          </h4>
        </div>
      </div>
    </div>
  ) : ( */}
    
    <div className=" py-6 px-4">
      <div>
        <div className="py-3  text-[16px] flex gap-2">
          -{" "}
          <h4>
Second academic year: Scholarship of 6,000€
All students required to pay a tuition fee will be automatically awarded a Scholarship of EUR 6 000 for the second academic year if having completed a minimum of 60 ECTS credits of studies in the personal study plan during the first academic year.

          {/* Applying for the 50% scholarship is possible after the first academic year. To be able to use the given option, your studies must have progressed according to the study plan. */}
          </h4>
        </div>
      </div>
    </div>
    
  {/* )} */}
</div>
                    {/* ) : (
                      <div className="border rounded-[10px]">
                      <div className="flex justify-center border-b rounded-t-[10px] bg-[#1DB459] py-2 ">
                        <h3 className="text-white text-center md:text-[20px] text-[16px]">
                          Scholarships for Bachelor's studies
                        </h3>
                      </div>
                      {course?.eligibility?.sat_test ? (
                        <div className=" py-6 px-4">
                          <div>
                            <div className="py-3  text-[16px] flex gap-2">
                              -{" "}
                              <h4>
                                For the second academic year, 50% scholarships
                                will be granted for the students successfully
                                completing the studies on the first academic
                                year (min.of 60 ECTS credits)
                              </h4>
                            </div>
                          </div>
                    
                          <div>
                            <div className="py-3  text-[16px] flex gap-2">
                              -{" "}
                              <h4>
                                {" "}
                                For the third academic year, 50% Tuition Fee
                                Waiver will be granted for the students
                                successfully completing min.120 ECTS credits in
                                the first and second academic years.
                              </h4>
                            </div>
                          </div>
                    
                          <div>
                            <div className="py-3  text-[16px] flex gap-2">
                              -{" "}
                              <h4>
                                If the student continues their studies in a
                                Master's programme after completing their
                                Bachelor's studies, the tuition fee for the
                                first year of Master's studies is EUR 7,500
                              </h4>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className=" py-6 px-4">
                          <div>
                            <div className="py-3  text-[16px] flex gap-2">
                              -{" "}
                              <h4>
                                Scholarship Discount- 10% early bird discount
                                for students who accept offer within 7 days and
                                pay fees within 28 days of notice of admission.
                                This is only applicable to Direct Application
                                students. After 29 May 2025, scholarship
                                discount will no longer be available.
                              </h4>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    )} */}
                  
                  </div>
                    {/* <div className="border rounded-[10px]">
                      <div className="flex justify-center border-b rounded-t-[10px] bg-[#1DB459] py-2 ">
                        <h3 className="text-white md:text-[20px] text-[16px]">
                          Early Bird and scholarships
                        </h3>
                      </div>
                      {course?.eligibility?.sat_test ? (
                        <div className=" py-6 px-4">
                          <h4 className="font-[600] text-[20px]">
                            Early Bird discount of EUR 6,000 for the first
                            academic year
                          </h4>
                          <div>
                            <div className="py-3  text-[16px] flex gap-2">
                              -{" "}
                              <h4>
                                To claim this discount, confirm your place and
                                pay the remaining EUR 7,500 within 21 days of
                                receiving your admission email.
                              </h4>
                            </div>
                          </div>

                          <h4 className="font-[600] text-[20px] pt-6">
                            Scholarship of EUR 6,000 for the second academic
                            year
                          </h4>
                          <div>
                            <div className="py-3  text-[16px] flex gap-2">
                              -{" "}
                              <h4>
                                {" "}
                                Earn a EUR 6,000 scholarship for the second
                                academic year by successfully completing a
                                minimum of 60 ECTS credits from your personal
                                study plan.
                              </h4>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className=" py-6 px-4">
                          <div>
                            <div className="py-3  text-[16px] flex gap-2">
                              -{" "}
                              <h4>
                                Scholarship Discount- 10% early bird discount
                                for students who accept offer within 7 days and
                                pay fees within 28 days of notice of admission.
                                This is only applicable to Direct Application
                                students. After 29 May 2025, scholarship
                                discount will no longer be available.
                              </h4>
                            </div>
                          </div>
                        </div>
                      )}
                    </div> */}
                  </div>
                )}
              </div>
              <div className="col-span-8">
          {category === "diploma program" ? (
        
    <img
                  className="rounded-lg md:w-[500px] md:h-[500px]"
                  src="/images/diplomaEsu.jpg"
                  // alt={datas?.cover_photo}
                  // style={{ width: "384px", height: "188px" }}
                />
           
          ) : (

                <img
                  className="rounded-lg"
                  src={course?.image}
                  // alt={datas?.cover_photo}
                  // style={{ width: "384px", height: "188px" }}
                />
              )}
                <h4 className="md:text-[32px] text-[25px] py-5 font-[600]">
                  Program Overviews
                </h4>
                <h5 className="text-[#848484] mb-5 text-[16px]">
                  {course?.overview}
                </h5>

{category === "diploma program" && (
  <div className="mb-3">
<h4 className="md:text-[32px] text-[25px] py-5 font-[600]">Why Choose This Program?</h4>
  
  <h5>- <span>High-Quality Education</span>: Benefit from Finland's world-class education system.</h5>
  <h5>- <span>Family Relocation Support</span>:  Bring your family along and enjoy a seamless transition.</h5>
  <h5>- <span>No Work Experience Required</span>: Open to everyone, regardless of work background.</h5>
  <h5>- <span>English-Taught</span>: No need to worry about learning a new language.</h5>
  <h5>- <span>Affordable Fees</span>: Competitive tuition and low processing fees.  </h5>
 </div>
)}
                {category === "degree program" ? (
                  <NavLink
                    to={`/degree-application-form/${course?.available_programmes}`}
                    className=" w-full mt-4"
                  >
                    <button
                      type="button"
                      className=" bg-[#D8D8D8]   rounded-[10px] md:text-[18px] text-[16px] px-6  py-3 shadow-md"
                    >
                      Get started
                    </button>
                  </NavLink>
                ) : category === "diploma program" ? (
                  <NavLink
                  to={`/diploma-application-form/${course?.available_programmes}`}
                  className=" w-full"
                >
                  <button
                    type="button"
                    className=" hover:bg-[#1DB459] border border-[#1DB459] hover:text-white  rounded-[10px] md:text-[18px] text-[16px] px-6 min-w-full  py-3 shadow-md"
                  >
                    Get started
                  </button>
                </NavLink>
                ) : (
                  <NavLink
                    to={`/masters-application-form/${course?.available_programmes}`}
                    className=" w-full mt-4"
                  >
                    <button
                      type="button"
                      className=" bg-[#D8D8D8]   rounded-[10px] md:text-[18px] text-[16px] px-6  py-3 shadow-md"
                    >
                      Get started
                    </button>
                  </NavLink>
                )}

                {/* {course?.career_paths && (
                  <>
                    <h4 className="md:text-[32px] text-[25px] py-5 font-[600]">
                      Career Paths for Graduates of the Program
                    </h4>
                    <div className="grid md:grid-cols-2 gap-3">
                      {course?.career_paths?.map((data: any, index: any) => (
                        <div className="flex flex-col">
                          <div className="flex justify-center text-white rounded-t-[10px] py-2 px-3 text-center bg-[#1DB459]">
                            {data.title}
                          </div>
                          <div className="py-3">
                            {data?.descriptions?.map((datas: any) => (
                              <div className="pb-3 px-5">-{datas}</div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="flex justify-center">
                      {category === "degree program" ? (
                        <NavLink
                          to={`/degree-application-form/${course?.available_programmes}`}
                          className=" w-full mt-4"
                        >
                          <button
                            type="button"
                            className=" bg-[#D8D8D8]   rounded-[10px] md:text-[18px] text-[16px] px-6  py-3 shadow-md"
                          >
                            Get started
                          </button>
                        </NavLink>
                      ) : (
                        <NavLink
                          to={`/masters-application-form/${course?.available_programmes}`}
                          className=" w-full mt-4"
                        >
                          <button
                            type="button"
                            className=" bg-[#D8D8D8]   rounded-[10px] md:text-[18px] text-[16px] px-6  py-3 shadow-md"
                          >
                            Get started
                          </button>
                        </NavLink>
                      )}
                    </div>
                  </>
                )} */}

{/* {course?.key_learning_outcomes && (
  <div>
   {category !== "degree program" && (
                  <div>
                    <h4 className="md:text-[32px] text-[25px] py-5 font-[600]">
                      Key Learning Outcomes from the Program
                    </h4>
                    <div className="grid md:grid-cols-2 gap-3">
                      {course?.key_learning_outcomes?.map(
                        (data: any, index: any) => (
                          <div className="flex border rounded-[10px] border-[#1DB459] flex-col">
                            <div className=" rounded-t-[10px] text-[20px] font-[600] pt-2 pb-3 px-4 ">
                              {data.title}
                            </div>
                            <div className="pb-3 px-4">{data.description}</div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
    </div>
)} */}
             
{/* {!(course?.name === "Paramedic Nurse" || course?.name === "International Business" ) && (
  <>
                <h4 className="md:text-[32px] text-[25px] py-5 font-[600]">
                  Eligibility
                </h4>
                {category === "degree program" && (
                  <h3 className=" md:text-[20px] text-[16px]">
                    FOR STUDENTS APPLYING WITH FINISHED DEGREE
                  </h3>
                )}

                <div className="grid md:grid-cols-2 gap-3">
                  {course?.eligibility?.bachelors_degree_required?.map(
                    (data: any, index: any) => (
                      <div className="flex gap-3">
                        <img
                          src="/images/openmoji_mark.svg"
                          className="w-10 h-10"
                          alt="hero"
                        />

                        <div className="  text-[18px] pt-2 pb-3 ">{data}</div>
                      </div>
                    )
                  )}
                </div>
                </>
)} */}



                {/* PARAMEDIC */} 
                {/* {(course?.name === "Paramedic Nurse" || course?.name === "International Business" ) && */}
                {category === "degree program" ? (
                <div>
                        <h4 className="md:text-[32px] text-[25px] py-5 font-[600]">
                          Language Certificate{" "}
                        </h4>

                        {/* {course?.eligibility?.language_proficiency?.map((data:any, index:any) => ( */}

                        <table className="w-full text-sm text-left">
                          <thead className="text-xs text-[#3B3D55]  bg-gray-50 font-[550]">
                            <tr>
                              <th scope="col" className="px-6 py-3">
                                Test Type
                              </th>

                              <th scope="col" className="px-6 py-3">
                                Requiremnt
                              </th>
                              <th scope="col" className="px-6 py-3"></th>
                            </tr>
                          </thead>
                          <tbody>

                          <tr className="bg-white border-b ">
                              <td className="px-6 py-4">IELTS Academic or IELTS Indicator or IELTS Online </td>
                              <td
                                // scope="row"
                                className="px-6 py-4 "
                              >
                           Minimum score 6.0 (writing band score 5.5)
                              </td>
                            </tr>

                            
                            <tr className="bg-white border-b ">
                              <td className="px-6 py-4">PTE Academic or PTE Academic Online
                              </td>
                              <td
                                // scope="row"
                                className="px-6 py-4 "
                              >
                                  Minimum score 58

                              </td>
                            </tr>
                            <tr className="bg-white border-b ">
                              <td className="px-6 py-4">TOEFL iBT or TOEFL iBT Home Edition or TOEFL iBT Paper edition or TOEFL iBT Ehancements
                              </td>
                              <td
                                // scope="row"
                                className="px-6 py-4 "
                              >
                                  Minimum score 80 (writing score min. 20)

                              </td>
                            </tr>

                            <tr className="bg-white border-b ">
                              <td className="px-6 py-4">C1 Advanced or C2 Proficiency
                              </td>
                              <td
                                // scope="row"
                                className="px-6 py-4 "
                              >
                                 Minimum score level C

                              </td>
                            </tr>

                            <tr className="bg-white border-b ">
                              <td className="px-6 py-4">             
Cambridge B2 First
                              </td>
                              <td
                                // scope="row"
                                className="px-6 py-4 "
                              >
                                 Minimum score C 170
                              </td>
                            </tr>

                            <tr className="bg-white border-b ">
                              <td className="px-6 py-4">
                              Cambridge International IGCSE
                              </td>
                              <td
                                // scope="row"
                                className="px-6 py-4 "
                              >
                                  First language in English Syllabus 0500 and 0990
                              </td>
                            </tr>

                            <tr className="bg-white border-b ">
                              <td className="px-6 py-4">
                              SAT test Evidence-Based Reading and Writing
                              </td>
                              <td
                                // scope="row"
                                className="px-6 py-4 "
                              >
                                Minimum score 600

                              </td>
                            </tr>

                            <tr className="bg-white border-b ">
                              <td className="px-6 py-4">
                              European Baccalaureate
                              </td>
                              <td
                                // scope="row"
                                className="px-6 py-4 "
                              >
                                English as L1 language with an accepted grade OR
    English as L2 or L3 language with a minimum grade of 7

                              </td>
                            </tr>

                            <tr className="bg-white border-b ">
                              <td className="px-6 py-4">
                              International Baccalaureate
                              </td>
                              <td
                                // scope="row"
                                className="px-6 py-4 "
                              >
                                  English as A1 language with an approved grade (minimum grade 2) OR
    English as A2 language with a minimum grade 5 OR
    English as A language (literature/language and literature/language and performance) with an accepted grade (minimum grade 2) OR
    English as B language with a minimum grade 5

                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="pt-8">A language test is not required when you have completed an upper secondary degree or a Bachelor/Master level degree in English at an institution located in the following countries: an EU or EEA country, Australia, Canada, Ireland, New Zealand, South-Africa, Switzerland, the United Kingdom, or the United States.</div>
                       
                        <div>
                        <h4 className="md:text-[28px] text-[25px] pt-5 font-[600]">
                        UPPER SECONDARY DEGREE CERTIFICATE
                        </h4>
                        {/* <h4 className=" text-[25px] pb-3 ">
                          An admitted student must
                        </h4> */}
                        <ul>
                          <li className="pb-2">Required minimum arithmetic average
                          </li>
                          <li className="pb-2">
                          The admission is based on upper secondary degree certificates. University-level certificates are not taken into consideration in the academic evaluation.

                          </li>
                          <li className="pb-2">
                          The requirements for grades are as follows, on a scale of failed to excellent:
</li>
                        <h4 className="pl-6">  -   Arithmetic grade average minimum of 65%

</h4>
                        </ul>
                      </div>
                       
                        {/* ))} */}
                      </div>) : category === "diploma program" ? (
                        <div>
                          </div>
                      ) : (
  <div>
  <h4 className="md:text-[32px] text-[25px] py-5 font-[600]">
    Language Certificate{" "}
  </h4>

  {/* {course?.eligibility?.language_proficiency?.map((data:any, index:any) => ( */}

  <table className="w-full text-sm text-left">
    <thead className="text-xs text-[#3B3D55]  bg-gray-50 font-[550]">
      <tr>
        <th scope="col" className="px-6 py-3">
          Test Type
        </th>

        <th scope="col" className="px-6 py-3">
          Requiremnt
        </th>
        <th scope="col" className="px-6 py-3"></th>
      </tr>
    </thead>
    <tbody>

    <tr className="bg-white border-b ">
        <td className="px-6 py-4">IELTS Academic or IELTS Retake or IELTS Online </td>
        <td
          // scope="row"
          className="px-6 py-4 "
        >
     6.5
        </td>
      </tr>

      
      {/* <tr className="bg-white border-b ">
        <td className="px-6 py-4">PTE Academic or PTE Academic Online
        </td>
        <td
          // scope="row"
          className="px-6 py-4 "
        >
            Minimum score 58

        </td>
      </tr> */}
      <tr className="bg-white border-b ">
        <td className="px-6 py-4">TOEFL iBT or TOEFL iBT Home Edition or TOEFL iBT Paper edition or TOEFL iBT Ehancements
        </td>
        <td
          // scope="row"
          className="px-6 py-4 "
        >
          90

        </td>
      </tr>
      <tr className="bg-white border-b ">
        <td className="px-6 py-4">PTE Academic or PTE Online Academic
        </td>
        <td
          // scope="row"
          className="px-6 py-4 "
        >
        62

        </td>
      </tr>




      <tr className="bg-white border-b ">
        <td className="px-6 py-4">Canbridge English: C1 Advanced
        </td>
        <td
          // scope="row"
          className="px-6 py-4 "
        >
         Pass on levels A,B or C
        </td>
      </tr>

      <tr className="bg-white border-b ">
        <td className="px-6 py-4">Canbridge English: C2 Proficiency
        </td>
        <td
          // scope="row"
          className="px-6 py-4 "
        >
         Pass on levels A,B or C or level C1
        </td>
      </tr>

      {/* <tr className="bg-white border-b ">
        <td className="px-6 py-4">             
Cambridge B2 First
        </td>
        <td
          // scope="row"
          className="px-6 py-4 "
        >
           Minimum score C 170
        </td>
      </tr>

      <tr className="bg-white border-b ">
        <td className="px-6 py-4">
        Cambridge International IGCSE
        </td>
        <td
          // scope="row"
          className="px-6 py-4 "
        >
            First language in English Syllabus 0500 and 0990
        </td>
      </tr>

      <tr className="bg-white border-b ">
        <td className="px-6 py-4">
        SAT test Evidence-Based Reading and Writing
        </td>
        <td
          // scope="row"
          className="px-6 py-4 "
        >
          Minimum score 600

        </td>
      </tr>

      <tr className="bg-white border-b ">
        <td className="px-6 py-4">
        European Baccalaureate
        </td>
        <td
          // scope="row"
          className="px-6 py-4 "
        >
          English as L1 language with an accepted grade OR
English as L2 or L3 language with a minimum grade of 7

        </td>
      </tr>

      <tr className="bg-white border-b ">
        <td className="px-6 py-4">
        International Baccalaureate
        </td>
        <td
          // scope="row"
          className="px-6 py-4 "
        >
            English as A1 language with an approved grade (minimum grade 2) OR
English as A2 language with a minimum grade 5 OR
English as A language (literature/language and literature/language and performance) with an accepted grade (minimum grade 2) OR
English as B language with a minimum grade 5

        </td>
      </tr> */}
    </tbody>
  </table>
  <div className="pt-8">Any other Cambridge tests besides C1 Advanced and C2 Proficiency are not accepted.</div>
 
  <div>
  {/* <h4 className="md:text-[28px] text-[25px] pt-5 font-[600]">
  UPPER SECONDARY DEGREE CERTIFICATE
  </h4>
  <ul>
    <li className="pb-2">Required minimum arithmetic average
    </li>
    <li className="pb-2">
    The admission is based on upper secondary degree certificates. University-level certificates are not taken into consideration in the academic evaluation.

    </li>
    <li className="pb-2">
    The requirements for grades are as follows, on a scale of failed to excellent:
</li>
  <h4 className="pl-6">  -   Arithmetic grade average minimum of 65%

</h4>
  </ul> */}
</div>
 
  {/* ))} */}
</div>
                      )}
{/* } */}
                {/* {category === "degree program" && (
                  <>
                    {course?.eligibility?.sat_test && (
                      <div>
                        <h4 className="md:text-[28px] text-[25px] pt-5 font-[600]">
                          FOR STUDENTS APPLYING WITH FINISHED DEGREE
                        </h4>
                        <h4 className=" text-[25px] pb-3 ">
                          An admitted student must
                        </h4>
                        <ul>
                          <li>Graduate by 31 August 2025</li>
                          <li>
                            Provide us with the following documents by email by
                            31 August 2025{" "}
                          </li>
                          <li className="pl-6">
                            1. Scan for your original Degree Certificate
                          </li>
                          <li className="pl-6">
                            2. Scan for your original Transcript of Records
                          </li>
                        </ul>
                      </div>
                    )}

                    {course?.eligibility?.sat_test && (
                      <div className="mt-6">
                        <h4 className="md:text-[28px] text-[25px] pt-5 font-[600]">
                          APPLYING WITH SAT TEST
                        </h4>
                        <h4 className=" text-[25px] pb-3 ">
                          Minimum requirements for the SAT test results
                        </h4>
                        <ul className="pl-6">
                          {course?.eligibility?.sat_test?.map(
                            (data: any, index: any) => (
                              <li className="list-disc  pb-2">{data}</li>
                            )
                          )}
                        </ul>
                      </div>
                    )}

                    {course?.eligibility?.sat_test && (
                      <div className="mt-6">
                        <h4 className="md:text-[28px] text-[25px] pt-5  font-[600]">
                          APPLYING WITH CAMBRIDGE INTERNATIONAL A LEVELS/PEARSON
                          EDEXCELL A LEVELS
                        </h4>
                        <h4 className=" text-[25px] pb-3 ">Required grades</h4>

                        <ul className="pl-6">
                          {course?.eligibility?.cambridge?.map(
                            (data: any, index: any) => (
                              <li className="list-disc  pb-2">{data}</li>
                            )
                          )}
                        </ul>
                      </div>
                    )}
                  </>
                )} */}

                {/* {category === "degree program" ? (
                  <>
                    {course?.eligibility?.language_proficiency && (
                      <div>
                        <h4 className="md:text-[32px] text-[25px] py-5 font-[600]">
                          Language proficiency
                        </h4>

                
                        <table className="w-full text-sm text-left">
                          <thead className="text-xs text-[#3B3D55]  bg-gray-50 font-[550]">
                            <tr>
                              <th scope="col" className="px-6 py-3">
                                Test Type
                              </th>

                              <th scope="col" className="px-6 py-3">
                                Requiremnt
                              </th>
                              <th scope="col" className="px-6 py-3"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="bg-white border-b ">
                              <td className="px-6 py-4">Cambridge B2 First</td>
                              <td
                                // scope="row"
                                className="px-6 py-4 "
                              >
                                {
                                  course?.eligibility?.language_proficiency
                                    ?.cambridge_b2_first
                                }
                              </td>
                            </tr>

                            <tr className="bg-white border-b ">
                              <td className="px-6 py-4">
                                TOEFL iBT $ TOEFL iBT Home Edition
                              </td>
                              <td
                                // scope="row"
                                className="px-6 py-4 "
                              >
                                {
                                  course?.eligibility?.language_proficiency
                                    ?.TOEFL_iBT_TOEFL_iBT_Home_Edition
                                }
                              </td>
                            </tr>

                            <tr className="bg-white border-b ">
                              <td className="px-6 py-4">
                                IELTS Academic or IELTS Online
                              </td>
                              <td
                                // scope="row"
                                className="px-6 py-4 "
                              >
                                {
                                  course?.eligibility?.language_proficiency
                                    ?.IELTS_Academic_or_IELTS_Online
                                }
                              </td>
                            </tr>

                            <tr className="bg-white border-b ">
                              <td className="px-6 py-4">
                                PTE Academic or PTE Academic Online
                              </td>
                              <td
                                // scope="row"
                                className="px-6 py-4 "
                              >
                                {
                                  course?.eligibility?.language_proficiency
                                    ?.PTE_Academic_or_PTE_Academic_Online
                                }
                              </td>
                            </tr>

                            <tr className="bg-white border-b ">
                              <td className="px-6 py-4">
                                Cambridge English C1 Advanced
                              </td>
                              <td
                                // scope="row"
                                className="px-6 py-4 "
                              >
                                {
                                  course?.eligibility?.language_proficiency
                                    ?.Cambridge_English_C1_Advanced
                                }
                              </td>
                            </tr>

                            <tr className="bg-white border-b ">
                              <td className="px-6 py-4">
                                Cambridge English C2 Proficiency
                              </td>
                              <td
                                // scope="row"
                                className="px-6 py-4 "
                              >
                                {
                                  course?.eligibility?.language_proficiency
                                    ?.Cambridge_English_C2_Proficiency
                                }
                              </td>
                            </tr>

                            <tr className="bg-white border-b ">
                              <td className="px-6 py-4">YKI Test in English</td>
                              <td
                                // scope="row"
                                className="px-6 py-4 "
                              >
                                {
                                  course?.eligibility?.language_proficiency
                                    ?.YKI_Test_in_English
                                }
                              </td>
                            </tr>
                          </tbody>
                        </table>
                     
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {course?.eligibility?.language_proficiency && (
                      <div>
                        <h4 className="md:text-[32px] text-[25px] py-5 font-[600]">
                          Language proficiency certificate needed{" "}
                        </h4>

                
                        <table className="w-full text-sm text-left">
                          <thead className="text-xs text-[#3B3D55]  bg-gray-50 font-[550]">
                            <tr>
                              <th scope="col" className="px-6 py-3">
                                Test Type
                              </th>

                              <th scope="col" className="px-6 py-3">
                                Requiremnt
                              </th>
                              <th scope="col" className="px-6 py-3"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="bg-white border-b ">
                              <td className="px-6 py-4">TOEFL iBT</td>
                              <td
                                // scope="row"
                                className="px-6 py-4 "
                              >
                                {
                                  course?.eligibility?.language_proficiency
                                    ?.TOEFL_iBT
                                }
                              </td>
                            </tr>

                            <tr className="bg-white border-b ">
                              <td className="px-6 py-4">IELTS Academic</td>
                              <td
                                // scope="row"
                                className="px-6 py-4 "
                              >
                                {
                                  course?.eligibility?.language_proficiency
                                    ?.IELTS_Academic
                                }
                              </td>
                            </tr>

                            <tr className="bg-white border-b ">
                              <td className="px-6 py-4">PTE Academic</td>
                              <td
                                // scope="row"
                                className="px-6 py-4 "
                              >
                                {
                                  course?.eligibility?.language_proficiency
                                    ?.PTE_Academic
                                }
                              </td>
                            </tr>

                            <tr className="bg-white border-b ">
                              <td className="px-6 py-4">Cambridge English</td>
                              <td
                                // scope="row"
                                className="px-6 py-4 "
                              >
                                {
                                  course?.eligibility?.language_proficiency
                                    ?.Cambridge_English
                                }
                              </td>
                            </tr>

                            <tr className="bg-white border-b ">
                              <td className="px-6 py-4">
                                GMAT (Focus Edition)
                              </td>
                              <td
                                // scope="row"
                                className="px-6 py-4 "
                              >
                                {course?.eligibility?.gmatt_gre?.GMAT_Focus}
                              </td>
                            </tr>

                            <tr className="bg-white border-b ">
                              <td className="px-6 py-4">
                                GMAT Exam(10th Edition)
                              </td>
                              <td
                                // scope="row"
                                className="px-6 py-4 "
                              >
                                {course?.eligibility?.gmatt_gre?.GMAT_Focus}
                              </td>
                            </tr>

                            <tr className="bg-white border-b ">
                              <td className="px-6 py-4">
                                GRE General Test : Verbal Reasoning
                              </td>
                              <td
                                // scope="row"
                                className="px-6 py-4 "
                              >
                                {
                                  course?.eligibility?.gmatt_gre?.GRE
                                    ?.verbal_reasoning
                                }
                              </td>
                            </tr>

                            <tr className="bg-white border-b ">
                              <td className="px-6 py-4">
                                GRE General Test : Quantitative Reasoning
                              </td>
                              <td
                                // scope="row"
                                className="px-6 py-4 "
                              >
                                {
                                  course?.eligibility?.gmatt_gre?.GRE
                                    ?.quantitative_reasoning
                                }
                              </td>
                            </tr>

                            <tr className="bg-white border-b ">
                              <td className="px-6 py-4">
                                GRE General Test: Analytical Writing
                              </td>
                              <td
                                // scope="row"
                                className="px-6 py-4 "
                              >
                                {
                                  course?.eligibility?.gmatt_gre?.GRE
                                    ?.analytical_writing
                                }
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </>
                )} */}
              </div>
            </div>
          </div>

          {/* <div className="flex flex-col sm:py-16 py-6 relative">
            <div className="basis-8/12">
              <div className=" text-[#000]  md:pb-5 pb-5">
                <h1 className="text-[24px] md:text-[42px] text-[#1DB459] font-[600]">
                  {courseDetails?.title}
                </h1>

                <h5 className="md:text-[24px] text-[16px] font-[400] capitalize">
                  {courseDetails?.ects}
                </h5>
              </div>

              <div className="flex md:hidden justify-between  mb-3 gap-2 mt-[10px]">
                {isLogin ? (
                  <NavLink
                    to={`/${courseDetails?.video_url}`}
                    className=" w-full"
                  >
                    <button
                      type="button"
                      className=" bg-[#1DB459]   text-[#FFFFFF] rounded-full md:text-[18px] text-[16px] px-6 min-w-full  py-3 shadow-md"
                    >
                      Apply
                    </button>
                  </NavLink>
                ) : (
                  <NavLink to={"/sign-in"} className=" w-full">
                    <button
                      type="button"
                      className=" bg-[#1DB459]   text-[#FFFFFF] rounded-full md:text-[18px] text-[16px] px-6 min-w-full  py-3 shadow-md"
                    >
                      Apply
                    </button>
                  </NavLink>
                )}
              </div>

              <hr className="pb-3 md:hidden" />

              <div className="grid md:grid-cols-3 grid-cols-2 md:space-x-5 pb-5 ">
                <div className="bg-[#F0F5FE] font-medium mr-4 md:px-2 px-5 md:py-2 py-1 rounded shadow">
                  <div className="md:text-[30px] text-[18px] text-[#4D4D4D] font-bold text-center">
                    € {formattedNumber}
                  </div>
                  <div className="md:text-[16px] text-[12px] text-[#4D4D4D] text-center">
                    Per Academic Year
                  </div>
                </div>
                <div className="bg-[#F0F5FE] font-medium mr-2 px-6  py-1 rounded shadow">
                  <h3 className="md:text-[30px] text-[18px] text-[#4D4D4D] font-bold text-center capitalize">
                    {courseDetails?.duration}
                  </h3>
                  <h5 className="md:text-[16px] text-[12px] text-[#4D4D4D] text-center">
                    Duration
                  </h5>
                </div>

                <div className="md:flex md:flex-col md:px-1 hidden">
                  {isLogin ? (
                    <NavLink
                      to={`/${courseDetails?.video_url}`}
                      className=" w-full"
                    >
                      <button
                        type="button"
                        className=" bg-[#1DB459]  text-[#FFFFFF] rounded-full text-[16px]  px-6 min-w-full  py-1.5 shadow-md"
                      >
                        Apply
                      </button>
                    </NavLink>
                  ) : (
                    <NavLink to={"/sign-in"} className=" w-full">
                      <button
                        type="button"
                        className=" bg-[#1DB459]  text-[#FFFFFF] rounded-full text-[16px]  px-6 min-w-full  py-1.5 shadow-md"
                      >
                        Apply
                      </button>
                    </NavLink>
                  )}
                </div>
              </div>
              <hr className="pt-3 hidden md:block" />

              <div className="mt-3">
                <p
                  className="pb-[24px] pt-[8px] "
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(courseDetails?.overview),
                  }}
                ></p>
              </div>
            </div>
            <div className="relative basis-4/12 md:ml-[100px]">
              <div className="mt-10 md:mt-0 sticky top-0 left-0">
                <div className="bg-white p-3 rounded flex-col border border-[#D9D9D9]">
                  <div className="flex-1 flex justify-center items-center">
                    <img
                      src={courseDetails?.image_url}
                      alt="hero"
                      className="w-full"
                    />
                  </div>
                  <div className="text-white mt-1 bg-[#1DB459] text-center w-full font-medium rounded-[4px] text-sm  py-2.5">
                    Key Information
                  </div>
                  <div className="flex justify-between pt-2 mt-1 mb-2 bg-[#F6FBFA] px-2 pb-1">
                    <h3 className="text-[12px]">{courseDetails?.key_1} </h3>
                    <h3 className="text-[12px]">{courseDetails?.value_1}</h3>
                  </div>
                  <div className="flex justify-between pt-2 mb-2  bg-[#F6FBFA] px-2 pb-1">
                    <h3 className="text-[12px]">{courseDetails?.key_2} </h3>
                    <h3 className="text-[12px]">{courseDetails?.value_2}</h3>
                  </div>
                  <div className="flex justify-between pt-2 mb-2  bg-[#F6FBFA] px-2 pb-1">
                    <h3 className="text-[12px]">{courseDetails?.key_3}</h3>
                    <h3 className="text-[12px]">{courseDetails?.value_3}</h3>
                  </div>
                  <div className="flex justify-between pt-2 mb-2 bg-[#F6FBFA] px-2 pb-1">
                    <h3 className="text-[12px]">{courseDetails?.key_4}</h3>
                    <h3 className="text-[12px] capitalize">
                      {courseDetails?.value_4}
                    </h3>
                  </div>
                  <div className="flex justify-between mb-2 pt-2  bg-[#F6FBFA] px-2 pb-1">
                    <h3 className="text-[12px]">{courseDetails?.key_5}</h3>
                    <h3 className="text-[12px] capitalize">
                      {courseDetails?.value_5}
                    </h3>
                  </div>
                  <div className="flex justify-between mb-2 pt-2  bg-[#F6FBFA] px-2 pb-1">
                    <h3 className="text-[12px]">{courseDetails?.key_6} </h3>
                    <h3 className="text-[12px] capitalize">
                      {courseDetails?.value_6}
                    </h3>
                  </div>
                  <div className="flex justify-between pt-2  bg-[#F6FBFA] px-2 pb-1">
                    <h3 className="text-[12px]">Language </h3>
                    <h3 className="text-[12px]">English</h3>
                  </div>

                  <div className="mt-[10px]">
                    <div className="">
                      {isLogin ? (
                        <NavLink to={`/${courseDetails?.video_url}`}>
                          <button
                            type="submit"
                            className="text-white bg-[#1DB459] w-full font-medium rounded-[4px] text-sm  py-2.5"
                          >
                            Apply Now
                          </button>
                        </NavLink>
                      ) : (
                        <NavLink to={"/sign-in"}>
                          <button
                            type="submit"
                            className="text-white bg-[#1DB459] w-full font-medium rounded-[4px] text-sm  py-2.5"
                          >
                            Apply Now
                          </button>
                        </NavLink>
                      )}
                    </div>

                    <div className="mt-[10px] mb-3">
                      <div className="">
                        <div className="text-[20px] font-medium text-[#000F2F]">
                          Need help?{" "}
                        </div>
                        <NavLink to="/contact-us">
                          <div className=" text-[#1DB459] font-[400] text-[14px] cursor-pointer">
                            contact us for more information
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      {/* <div className=" sm:px-16 px-6 md:flex md:justify-center items-start md:pt-5 pt-10">
        <div className="xl:max-w-[1200px] w-full mx-auto">
          <div className="flex md:flex-row flex-col sm:py-16 py-6 relative">
            <div className="basis-8/12">
              <div className=" text-[#000]  md:pb-5 pb-5">
                <h1 className="text-[24px] md:text-[42px] text-[#1DB459] font-[600]">
                  {courseDetails?.title}
                </h1>
    
                <h5 className="md:text-[24px] text-[16px] font-[400] capitalize">
                  {courseDetails?.ects}
                </h5>
              </div>

              <div className="flex md:hidden justify-between  mb-3 gap-2 mt-[10px]">
                {isLogin ? (
                  <NavLink
                    to={`/${courseDetails?.video_url}`}
                    className=" w-full"
                  >
                    <button
                      type="button"
                      className=" bg-[#1DB459]   text-[#FFFFFF] rounded-full md:text-[18px] text-[16px] px-6 min-w-full  py-3 shadow-md"
                    >
                      Apply
                    </button>
                  </NavLink>
                ) : (
                  <NavLink to={"/sign-in"} className=" w-full">
                    <button
                      type="button"
                      className=" bg-[#1DB459]   text-[#FFFFFF] rounded-full md:text-[18px] text-[16px] px-6 min-w-full  py-3 shadow-md"
                    >
                      Apply
                    </button>
                  </NavLink>
                )}

           
              </div>

              <hr className="pb-3 md:hidden" />

              <div className="grid md:grid-cols-3 grid-cols-2 md:space-x-5 pb-5 ">
                <div className="bg-[#F0F5FE] font-medium mr-4 md:px-2 px-5 md:py-2 py-1 rounded shadow">
                  <div className="md:text-[30px] text-[18px] text-[#4D4D4D] font-bold text-center">
                    € {formattedNumber}
                  </div>
                  <div className="md:text-[16px] text-[12px] text-[#4D4D4D] text-center">
                    Per Academic Year
                  </div>
                </div>
                <div className="bg-[#F0F5FE] font-medium mr-2 px-6  py-1 rounded shadow">
                  <h3 className="md:text-[30px] text-[18px] text-[#4D4D4D] font-bold text-center capitalize">
                    {courseDetails?.duration}
                  </h3>
                  <h5 className="md:text-[16px] text-[12px] text-[#4D4D4D] text-center">
                    Duration
                  </h5>
                </div>

                <div className="md:flex md:flex-col md:px-1 hidden">
                  {isLogin ? (
                    <NavLink
                      to={`/${courseDetails?.video_url}`}
                      className=" w-full"
                    >
                      <button
                        type="button"
                        className=" bg-[#1DB459]  text-[#FFFFFF] rounded-full text-[16px]  px-6 min-w-full  py-1.5 shadow-md"
                      >
                        Apply
                      </button>
                    </NavLink>
                  ) : (
                    <NavLink to={"/sign-in"} className=" w-full">
                      <button
                        type="button"
                        className=" bg-[#1DB459]  text-[#FFFFFF] rounded-full text-[16px]  px-6 min-w-full  py-1.5 shadow-md"
                      >
                        Apply
                      </button>
                    </NavLink>
                  )}
              
                </div>
              </div>
              <hr className="pt-3 hidden md:block" />

              <div className="mt-3">
              <p
                  className="pb-[24px] pt-[8px] "
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(courseDetails?.overview),
                  }}
                ></p>
              </div>
            </div>
            <div className="relative basis-4/12 md:ml-[100px]">
              <div className="mt-10 md:mt-0 sticky top-0 left-0">
                <div className="bg-white p-3 rounded flex-col border border-[#D9D9D9]">
                  <div className="flex-1 flex justify-center items-center">
                    <img
                      src={courseDetails?.image_url}
                      alt="hero"
                      className="w-full"
                    />
                  </div>
                  <div className="text-white mt-1 bg-[#1DB459] text-center w-full font-medium rounded-[4px] text-sm  py-2.5">
                    Key Information
                  </div>
                  <div className="flex justify-between pt-2 mt-1 mb-2 bg-[#F6FBFA] px-2 pb-1">
                    <h3 className="text-[12px]">{courseDetails?.key_1} </h3>
                    <h3 className="text-[12px]">{courseDetails?.value_1}</h3>
                  </div>
                  <div className="flex justify-between pt-2 mb-2  bg-[#F6FBFA] px-2 pb-1">
                    <h3 className="text-[12px]">{courseDetails?.key_2} </h3>
                    <h3 className="text-[12px]">{courseDetails?.value_2}</h3>
                  </div>
                  <div className="flex justify-between pt-2 mb-2  bg-[#F6FBFA] px-2 pb-1">
                    <h3 className="text-[12px]">{courseDetails?.key_3}</h3>
                    <h3 className="text-[12px]">{courseDetails?.value_3}</h3>
                  </div>
                  <div className="flex justify-between pt-2 mb-2 bg-[#F6FBFA] px-2 pb-1">
                    <h3 className="text-[12px]">{courseDetails?.key_4}</h3>
                    <h3 className="text-[12px] capitalize">
                      {courseDetails?.value_4}
                    </h3>
                  </div>
                  <div className="flex justify-between mb-2 pt-2  bg-[#F6FBFA] px-2 pb-1">
                    <h3 className="text-[12px]">{courseDetails?.key_5}</h3>
                    <h3 className="text-[12px] capitalize">
                      {courseDetails?.value_5}
                    </h3>
                  </div>
                  <div className="flex justify-between mb-2 pt-2  bg-[#F6FBFA] px-2 pb-1">
                    <h3 className="text-[12px]">{courseDetails?.key_6} </h3>
                    <h3 className="text-[12px] capitalize">
                      {courseDetails?.value_6}
                    </h3>
                  </div>
                  <div className="flex justify-between pt-2  bg-[#F6FBFA] px-2 pb-1">
                    <h3 className="text-[12px]">Language </h3>
                    <h3 className="text-[12px]">English</h3>
                  </div>

                  <div className="mt-[10px]">
                
                    <div className="">
                      {isLogin ? (
                        <NavLink to={`/${courseDetails?.video_url}`}>
                          <button
                            type="submit"
                            className="text-white bg-[#1DB459] w-full font-medium rounded-[4px] text-sm  py-2.5"
                          >
                            Apply Now
                          </button>
                        </NavLink>
                      ) : (
                        <NavLink to={"/sign-in"}>
                          <button
                            type="submit"
                            className="text-white bg-[#1DB459] w-full font-medium rounded-[4px] text-sm  py-2.5"
                          >
                            Apply Now
                          </button>
                        </NavLink>
                      )}
                    </div>

                    <div className="mt-[10px] mb-3">
                      <div className="">
                        <div className="text-[20px] font-medium text-[#000F2F]">
                          Need help?{" "}
                        </div>
                        <NavLink to="/contact-us">
                          <div className=" text-[#1DB459] font-[400] text-[14px] cursor-pointer">
                            contact us for more information
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

           
            </div>
          </div>
        </div>
      </div> */}
      <div className="">
        <Footer />
      </div>

      {/* <!-- End block --> */}
    </>
  );
};

export default CourseDetails;
